<script setup>
import { reactive, watch } from 'vue';
import { getEnvironment } from '~/functions/utils-functions';
import selfServiceFormWrapper from '~/functions/wrappers/self-service-form-wrapper';
import setupSelfServiceMergeLists from '~/functions/setup/setup-self-service-merge-lists';
import selfServiceForm from '~/components/functional/self-service-form.vue';
import { selfServiceSchema } from '~/constants/schemas';
import PmButton from '~/components/ui/pm-button.vue';
import permissionsWrapper from '~/functions/wrappers/permissions';
import store from '~/store/index';
import { convertSelfServiceSchemeToDBProfile } from '~/functions/conversion-functions';
import { onMounted, onBeforeUnmount } from 'vue';

const props = defineProps({
  selfServiceFunctions: {
    default: () => {},
    type: Object
  },
  reloadProfiles: {
    type: Function,
    default: () => {}
  },
  featureFlagHandler: {
    default: () => {},
    type: Object
  },
  selfServiceProfile: {
    type: Object,
    default: selfServiceSchema()
  },
  defaultThemes: {
    default: () => [],
    type: Array
  }
});
const emit = defineEmits([
  'fetchSelfServiceProfile',
  'reloadProfiles',
  'openPreview'
]);
const profile = reactive(props.selfServiceProfile);
const environment = getEnvironment(process.env.VUE_APP_STRAPI_URL);

const permissionsHandler = permissionsWrapper(profile.permissions);

const {
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  variableRecordHandler
} = setupSelfServiceMergeLists(
  profile,
  permissionsHandler,
  reactive,
  !props.featureFlagHandler.allowedFeaturePerProfile(profile.company)['rte']
);

const controls = selfServiceFormWrapper(
  profile,
  props.selfServiceFunctions,
  props.defaultThemes,
  props.featureFlagHandler,
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  permissionsHandler,
  {
    autosave: true,
    fetchNewProfileInstance(profileId) {
      return emit('fetchSelfServiceProfile', profileId);
    },
    afterDetailsSaved() {
      emit('reloadProfiles');
    },
    onUpdated: (profile) => {
      store.commit('selfServicePreview/setProfile', {
        ...(profile?.fullProfile || {}),
        ...convertSelfServiceSchemeToDBProfile(profile, false)
      });
    }
  }
);
watch(controls.sectionAccordions, (updatedValue) => {
  if (!updatedValue) {
    sessionStorage.removeItem('accordionSections');
  } else {
    sessionStorage.setItem('accordionSections', JSON.stringify(updatedValue));
  }
});
onMounted(() => {
  // setTimeout(() => {
  store.commit('selfServicePreview/hideLoader');
  store.commit('selfServicePreview/setProfile', {
    ...(profile?.fullProfile || {}),
    ...convertSelfServiceSchemeToDBProfile(profile, false)
  });
  // }, 1000);
});
onBeforeUnmount(() => {
  store.commit('selfServicePreview/showLoader');
  store.commit('selfServicePreview/resetProfile');
});

const handleOpen = () => {
  emit('openPreview');
};
</script>

<template>
  <div class="flex">
    <self-service-form
      :controls="controls"
      :sectionHandler="sectionHandler"
      :linksArrayHandler="linksArrayHandler"
      :socialMediaArrayHandler="socialMediaArrayHandler"
      :featureFlagHandler="featureFlagHandler"
      :popupsArrayHandler="popupsArrayHandler"
      :variableRecordHandler="variableRecordHandler"
      :environment="environment"
    >
      <template #controlbar>
        <div
          class="flex-wrap flex py-8 px-6 bg-gray-100 border-t xl:flex-nowrap xl:pl-16"
        >
          <pm-button
            class="flex-1 w-96 mr-8 xl:flex-none"
            :compact="true"
            :background="'outline'"
            @click="handleOpen"
            >Preview</pm-button
          >
          <pm-button
            class="flex-1 w-96 publish-btn-container xl:flex-none xl:mr-8"
            :compact="true"
            :disabled="
              controls.savingProfile.subscribing ||
              controls.savingProfile.loading
            "
            @click="() => controls.publish(true)"
            >{{ controls.saveButtonTitle.value }}</pm-button
          >
        </div>
      </template>
    </self-service-form>
  </div>
</template>

<style lang="css" scoped>
@import url('../../self-service.css');
</style>
