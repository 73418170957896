<!-- eslint-disable vue/no-mutating-props -->
<template>
  <main class="relative flex-1 lg:mr-2" @input="(e) => debounceAutoSave(e)">
    <div class="h-6 bg-transparent" />
    <div class="mb-6 profile-details">
      <div
        class="flex px-12 py-12 justify-center items-center bg-white"
        v-show="profile.publishedAt"
      >
        <p class="truncate w-0 flex-1 text-3xl text-black">
          My digital profile link:
          <a class="underline" target="_blank" :href="getProfileUrl()">{{
            getProfileUrl()
          }}</a>
        </p>
        <i
          @click="copy(getProfileUrl())"
          class="fa-regular fa-clone cursor-pointer text-5xl mx-12 text-gray-400"
        ></i>
      </div>
      <accordion
        v-permissions="permissions"
        itemId="personalDetails"
        title="Personal details"
        padding="p-0"
        v-model="sectionAccordions['personal-details']"
        :open="sectionAccordions['personal-details']"
      >
        <section id="profile-picture" />
        <section id="fullname" />
        <section id="jobtitle" />
        <div v-permissions="permissions" itemId="personalDetails.content">
          <v-input-wrapper
            v-permissions="permissions"
            itemId="personalDetails.profileImage"
          >
            <div class="flex p-12">
              <loading-image
                containerClss="h-32 w-32 rounded-full border overflow-hidden"
                imgClss="h-32 w-32"
                :url="
                  formatImageUrl(getValueOrDefaultToMaster('profilePhoto.url'))
                "
                :loading="profilePhotoLoading"
              />
              <div
                class="flex justify-center align-center flex-col flex-1 ml-8"
              >
                <span class="text-4xl text-black mb-2"
                  >Profile picture<span
                    class="ml-1 text-4xl text-red-600 relative bottom-2"
                    v-show="itemIsRequired('personalDetails.profileImage')"
                    >*
                  </span></span
                >
                <div class="flex">
                  <label for="profile-image-input" :class="classes.blueHeader">
                    Upload
                    <i class="fa-solid fa-upload ml-4 text-2xl" />
                  </label>
                  <input
                    id="profile-image-input"
                    name="profile-image-input"
                    @change="
                      (e) => {
                        croppingImage.url = createObjectURL(e.target.files[0]);
                        croppingImage.key = 'profilePhoto';
                        croppingImage.og = createObjectURL(e.target.files[0]);
                        setValueToNull('profile-image-input');
                        openCropper();
                      }
                    "
                    accept="image/*"
                    hidden
                    type="file"
                  />

                  <div
                    v-if="profile?.profilePhoto?.url"
                    style="width: 2px"
                    class="bg-gray-200 mx-6"
                  />
                  <v-modal
                    v-if="profile?.profilePhoto?.url"
                    classes="modal-content-small"
                  >
                    <template #trigger="{ open }">
                      <span @click="open" :class="classes.blueHeader">
                        Edit
                        <i class="fa-solid fa-pencil ml-4 text-2xl" />
                      </span>
                    </template>
                    <template #content="{ close }">
                      <div
                        @click="
                          () => {
                            close();
                          }
                        "
                        class="ml-8 cursor-pointer rounded-full flex justify-center items-center p-2 absolute w-10 h-10 right-1"
                      >
                        <i
                          class="fa-solid fa-xmark relative text-gray-400 text-3xl"
                        ></i>
                      </div>
                      <photo-editor
                        :aspect="fetchCropperConfig('profilePhoto', 'aspect')"
                        :maxWidth="
                          fetchCropperConfig('profilePhoto', 'maxWidth')
                        "
                        :maxHeight="
                          fetchCropperConfig('profilePhoto', 'maxHeight')
                        "
                        :minWidth="
                          fetchCropperConfig('profilePhoto', 'minWidth')
                        "
                        :minHeight="
                          fetchCropperConfig('profilePhoto', 'minHeight')
                        "
                        :imageSrc="profile.profilePhoto"
                        :loading="isImageUploading(profile.profilePhoto?.url)"
                        :fallback="
                          formatImageUrl(
                            getValueOrDefaultToMaster('profilePhoto.url')
                          )
                        "
                        @image="
                          (e) => {
                            handleImageUpload(e, 'profilePhoto');
                            closeModal('modal-wrapper');
                          }
                        "
                      />
                    </template>
                  </v-modal>
                </div>
              </div>
              <permission-controls
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="personalDetails.profileImage"
                v-show="MODE == 'permissions'"
                class="relative left-3"
              />
            </div>
          </v-input-wrapper>

          <section id="personal-details" />
          <div class="bg-white pb-8 pt-20 px-8">
            <v-input
              v-if="isDesktopTemplate"
              itemId="personalDetails.fullName"
              name="fullName"
              @updatePermissions="handlePermissionUpdate"
              v-model="profile.firstName"
              v-permissions="permissions"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="classes.inputs"
              :placeholder="getDefaultFromMaster('firstName')"
            >
              Full name<span
                class="ml-1 text-2xl text-red-600"
                v-show="itemIsRequired('personalDetails.fullName')"
                >*
              </span>
            </v-input>
            <v-input
              v-if="!isDesktopTemplate"
              itemId="personalDetails.firstName"
              name="firstName"
              @updatePermissions="handlePermissionUpdate"
              v-model="profile.firstName"
              v-permissions="permissions"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="classes.inputs"
              :placeholder="getDefaultFromMaster('firstName')"
            >
              Title: First name<span
                class="ml-1 text-2xl text-red-600"
                v-show="
                  itemIsRequired('personalDetails.firstName') ||
                  itemIsRequired('personalDetails.fullName')
                "
                >*
              </span>
            </v-input>
            <v-input
              v-if="!isDesktopTemplate"
              itemId="personalDetails.lastName"
              name="lastName"
              @updatePermissions="handlePermissionUpdate"
              v-model="profile.lastName"
              v-permissions="permissions"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="classes.inputs"
              :placeholder="getDefaultFromMaster('lastName')"
            >
              Title: Last name<span
                class="ml-1 text-2xl text-red-600"
                v-show="itemIsRequired('personalDetails.lastName')"
                >*
              </span>
            </v-input>
            <v-input
              itemId="personalDetails.titleSize"
              name="titleSize"
              type="number"
              v-permissions="permissions"
              v-model="profile.titleSize"
              @updatePermissions="handlePermissionUpdate"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="
                classes.inputs.replace('flex-1', '') + ' w-20 flex-0 mr-auto'
              "
              :placeholder="getDefaultFromMaster('titleSize')"
            >
              Title: Font size<span
                class="ml-1 text-2xl text-red-600"
                v-show="itemIsRequired('personalDetails.titleSize')"
                >*
              </span>
            </v-input>
            <v-input
              itemId="personalDetails.jobTitle"
              name="jobTitle"
              maxlength="150"
              v-permissions="permissions"
              v-model="profile.jobTitle"
              @updatePermissions="handlePermissionUpdate"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="classes.inputs"
              :placeholder="getDefaultFromMaster('jobTitle')"
            >
              Subtitle: Position / Subheading<span
                class="ml-1 text-2xl text-red-600"
                v-show="itemIsRequired('personalDetails.jobTitle')"
                >*
              </span>
            </v-input>
            <v-input
              itemId="personalDetails.subtitleSize"
              name="subtitleSize"
              type="number"
              v-permissions="permissions"
              v-model="profile.subtitleSize"
              @updatePermissions="handlePermissionUpdate"
              :permissions="managePermissions"
              :mode="MODE"
              :placeholderClasses="classes.placeholder"
              :inputClasses="
                classes.inputs.replace('flex-1', '') + ' w-20 flex-0 mr-auto'
              "
              :placeholder="getDefaultFromMaster('subtitleSize')"
            >
              Subtitle: Font Size<span
                class="ml-1 text-2xl text-red-600"
                v-show="itemIsRequired('personalDetails.subtitleSize')"
                >*
              </span>
            </v-input>
            <p class="text-2xl mb-8" v-if="!isDesktopTemplate"
              >Do you require further changes to your profile?
            </p>
            <a
              v-if="!isDesktopTemplate"
              class="w-full"
              href="https://profileme.app/support-request/"
              target="_blank"
            >
              <pm-button :compact="true">Request edits</pm-button>
            </a>
            <template v-if="isDesktopTemplate">
              <section id="contactnumber" />
              <v-input
                itemId="personalDetails.mainContactNumber"
                name="contactnumber"
                @updatePermissions="handlePermissionUpdate"
                v-model="profile.mainContactNumber"
                v-permissions="permissions"
                :permissions="managePermissions"
                :mode="MODE"
                :type="'tel'"
                :placeholderClasses="classes.placeholder"
                :inputClasses="classes.inputs"
                :placeholder="getDefaultFromMaster('mainContactNumber')"
              >
                Main contact number<span
                  class="ml-1 text-2xl text-red-600"
                  v-show="itemIsRequired('personalDetails.mainContactNumber')"
                  >*
                </span>
              </v-input>
              <v-input
                v-permissions="permissions"
                v-model="profile.whatsappNumber"
                itemId="personalDetails.whatsappNumber"
                name="whatsappnumber"
                @updatePermissions="handlePermissionUpdate"
                :permissions="managePermissions"
                :mode="MODE"
                :type="'tel'"
                :placeholderClasses="classes.placeholder"
                :inputClasses="classes.inputs"
                :placeholder="getDefaultFromMaster('whatsappNumber')"
              >
                Whatsapp number<span
                  class="ml-1 text-2xl text-red-600"
                  v-show="itemIsRequired('personalDetails.whatsappNumber')"
                  >*
                </span>
              </v-input>
              <v-input
                v-permissions="permissions"
                v-model="profile.officeNumber"
                name="officeNumber"
                itemId="personalDetails.officeNumber"
                @updatePermissions="handlePermissionUpdate"
                :permissions="managePermissions"
                :mode="MODE"
                :type="'tel'"
                :placeholderClasses="classes.placeholder"
                :inputClasses="classes.inputs"
                :placeholder="getDefaultFromMaster('officeNumber')"
              >
                Office number<span
                  class="ml-1 text-2xl text-red-600"
                  v-show="itemIsRequired('personalDetails.officeNumber')"
                  >*
                </span>
              </v-input>
              <section id="emailaddress" />
              <v-input
                v-model="profile.emailAddress"
                v-permissions="permissions"
                itemId="personalDetails.emailAddress"
                name="emailAddress"
                @updatePermissions="handlePermissionUpdate"
                :permissions="managePermissions"
                :mode="MODE"
                :placeholderClasses="classes.placeholder"
                :inputClasses="classes.inputs"
                :placeholder="getDefaultFromMaster('emailAddress')"
              >
                Email address<span
                  class="ml-1 text-2xl text-red-600"
                  v-show="itemIsRequired('personalDetails.emailAddress')"
                  >*
                </span>
              </v-input>
              <address-input
                itemId="personalDetails.navigateToOffice"
                @updatePermissions="handlePermissionUpdate"
                v-permissions="permissions"
                :permissions="managePermissions"
                :mode="MODE"
                :placeholderClasses="classes.placeholder"
                :address="profile.navigateToOffice"
                @update="
                  (e) => {
                    debounceAutoSave({
                      target: { name: 'navigateToOffice', value: e }
                    });
                  }
                "
                name="navigateToOffice-wrapper"
                :inputClasses="classes.inputs"
                :placeholder="getDefaultFromMaster('navigateToOffice')"
              >
                Navigate to office<span
                  class="ml-1 text-2xl text-red-600"
                  v-show="itemIsRequired('personalDetails.navigateToOffice')"
                  >*
                </span>
              </address-input>
            </template>
          </div>
        </div>
      </accordion>
    </div>
    <div
      class="my-6 themes"
      v-if="
        stylingTemplates?.length > 0 &&
        (idIsATheme || !profile.stylingId) &&
        hasStyling
      "
    >
      <accordion
        v-permissions="permissions"
        itemId="themes"
        padding="p-0"
        title="Themes"
        v-model="sectionAccordions['Themes']"
        :open="sectionAccordions['Themes']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="themes"
          />
        </template>
        <themes-form
          v-permissions="permissions"
          itemId="themes-content"
          :value="profile.stylingId || profile.masterProfile.stylingId"
          :templates="stylingTemplates"
          :classes="classes"
          @update="
            ({ stylingId, cssPlug, layout, fontFamily }) => {
              profile.stylingId = stylingId;
              profile.stylingTemplateCssPlug = cssPlug;
              profile.templateLayout = layout;
              profile.fontFamily = fontFamily;
              debounceAutoSave();
            }
          "
        ></themes-form>
      </accordion>
    </div>
    <div class="my-6 appearance-and-branding" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        itemId="appearanceAndBranding"
        padding="p-0"
        title="Appearance and branding"
        v-model="sectionAccordions['branding']"
        :open="sectionAccordions['branding']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="appearanceAndBranding"
          />
        </template>
        <section id="company-logo" />
        <section id="primary-color" />
        <section id="secondary-color" />
        <div
          v-permissions="permissions"
          itemId="appearanceAndBranding.content"
          class="bg-white p-6 lg:p-12"
        >
          <div
            v-permissions="permissions"
            itemId="appearance.colors"
            class="mb-8"
          >
            <div
              class="flex justify-between items-center font-bold text-3xl text-black py-12"
            >
              <div> Colors </div>
              <permission-controls
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="appearance.colors"
                v-show="MODE == 'permissions'"
              />
            </div>
            <v-input-wrapper
              v-permissions="permissions"
              itemId="appearance.backgroundColor"
              class="border-b"
            >
              <div
                class="flex justify-between py-6 my-3 align-center items-center"
              >
                <div :class="classes.colorPlaceholder + ' whitespace-nowrap'">
                  Background colour
                  <span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.backgroundColor')"
                    >*
                  </span>
                </div>
                <div class="flex justify-end align-center items-center">
                  <color-picker
                    right
                    name="backgroundColor"
                    id="background-color-picker"
                    :defaultColour="getMasterProfileDefault('background-color')"
                    v-model="profile.cssVariables['background-color']"
                    @change="debounceAutoSave"
                  />
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    itemId="appearance.backgroundColor"
                    class="relative"
                  />
                </div>
              </div>
            </v-input-wrapper>
            <v-input-wrapper
              v-permissions="permissions"
              itemId="appearance.nameFontColor"
              class="border-b"
            >
              <div
                class="flex justify-between py-6 my-3 align-center items-center"
              >
                <div :class="classes.colorPlaceholder + ' whitespace-nowrap'">
                  Title font colour
                  <span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.nameFontColor')"
                    >*
                  </span>
                </div>
                <div class="flex justify-center align-center items-center">
                  <color-picker
                    right
                    name="nameFontColor"
                    id="name-font-color-picker"
                    :defaultColour="getMasterProfileDefault('name-font-color')"
                    v-model="profile.cssVariables['name-font-color']"
                    @change="debounceAutoSave"
                  />
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    itemId="appearance.nameFontColor"
                    class="relative"
                  />
                </div>
              </div>
            </v-input-wrapper>
            <v-input-wrapper
              v-permissions="permissions"
              itemId="appearance.subtitleFontColor"
              class="border-b"
            >
              <div
                class="flex justify-between py-6 my-3 align-center items-center"
              >
                <div :class="classes.colorPlaceholder + ' whitespace-nowrap'">
                  Subtitle font colour
                  <span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.subtitleFontColor')"
                    >*
                  </span>
                </div>
                <div class="flex justify-center align-center items-center">
                  <color-picker
                    right
                    name="subtitleFontColor"
                    id="subtitle-font-color-picker"
                    :defaultColour="
                      getMasterProfileDefault('subtitle-font-color')
                    "
                    v-model="profile.cssVariables['subtitle-font-color']"
                    @change="debounceAutoSave"
                  />
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    itemId="appearance.subtitleFontColor"
                    class="relative"
                  />
                </div>
              </div>
            </v-input-wrapper>
            <v-input-wrapper
              v-permissions="permissions"
              itemId="appearance.fontColor"
              class="border-b"
            >
              <div
                class="flex justify-between py-6 my-3 align-center items-center"
              >
                <div :class="classes.colorPlaceholder + ' whitespace-nowrap'"
                  >Button font colour<span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.fontColor')"
                    >*
                  </span></div
                >
                <div class="flex justify-center align-center items-center">
                  <color-picker
                    right
                    name="fontColor"
                    id="font-color-picker"
                    :defaultColour="getMasterProfileDefault('font-color')"
                    v-model="profile.cssVariables['font-color']"
                    @change="debounceAutoSave"
                  />
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    itemId="appearance.fontColor"
                    class="relative"
                  />
                </div>
              </div>
            </v-input-wrapper>
            <v-input-wrapper
              v-permissions="permissions"
              itemId="appearance.iconColor"
              class="border-b"
            >
              <div
                class="flex justify-between py-6 my-3 align-center items-center"
              >
                <div :class="classes.colorPlaceholder + ' whitespace-nowrap'"
                  >Icon colour<span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.iconColor')"
                    >*
                  </span></div
                >

                <div class="flex justify-center align-center items-center">
                  <color-picker
                    right
                    name="iconColor"
                    id="icon-color-picker"
                    :defaultColour="getMasterProfileDefault('icon-color')"
                    v-model="profile.cssVariables['icon-color']"
                    @change="debounceAutoSave"
                  />
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    itemId="appearance.iconColor"
                    class="relative"
                  />
                </div>
              </div>
            </v-input-wrapper>
          </div>
          <div v-permissions="permissions" itemId="appearance.iconStyle">
            <v-input-wrapper>
              <div
                class="flex justify-between items-center font-bold text-3xl text-black py-12"
              >
                <div>
                  Icon style
                  <span
                    class="ml-1 text-3xl text-red-600"
                    v-show="itemIsRequired('appearance.iconStyle')"
                    >*
                  </span>
                </div>

                <permission-controls
                  @updated="handlePermissionUpdate"
                  :permissions="managePermissions"
                  itemId="appearance.iconStyle"
                  v-show="MODE == 'permissions'"
                />
              </div>
              <div class="flex my-8 xs:items-center flex-row justify-between">
                <div
                  class="flex lg:block"
                  @click="changeIconStyle('light')"
                  :class="`text-brand-color text-3xl my-4 ${
                    profile.iconStyle === 'light'
                      ? 'font-bold active-icon-style'
                      : ''
                  }`"
                >
                  Light
                  <i class="fa-light fa-globe ml-4 text-4xl text-black"></i>
                </div>
                <div class="bg-gray-200 w-1 h-8 mx-4 hidden xs:block" />
                <div
                  class="flex lg:block"
                  @click="changeIconStyle('regular')"
                  :class="`text-brand-color text-3xl my-4${
                    profile.iconStyle === 'regular'
                      ? 'font-bold active-icon-style'
                      : ''
                  }`"
                >
                  Outline
                  <i class="fa-regular fa-globe ml-4 text-4xl text-black"></i>
                </div>
                <div class="bg-gray-200 w-1 h-8 ml-4 hidden xs:block" />
                <div
                  class="flex lg:block"
                  @click="changeIconStyle('solid')"
                  :class="`text-brand-color text-3xl my-4 ${
                    profile.iconStyle === 'solid'
                      ? 'font-bold active-icon-style'
                      : ''
                  }`"
                >
                  Solid
                  <i class="fa-solid fa-globe ml-4 text-4xl text-black"></i>
                </div>
                <div class="bg-gray-200 w-1 h-8 ml-4 hidden xs:block" />

                <div
                  class="flex lg:block"
                  @click="changeIconStyle('duotone')"
                  :class="`text-brand-color  text-3xl my-4 ${
                    profile.iconStyle === 'duotone'
                      ? 'font-bold active-icon-style'
                      : ''
                  }`"
                >
                  Duotone
                  <i class="fa-duotone fa-globe ml-4 text-4xl text-black"></i>
                </div>
              </div>
            </v-input-wrapper>
          </div>
          <div
            class="grid divide-y-2 gap-8"
            v-permissions="permissions"
            itemId="branding"
          >
            <div
              class="flex justify-between items-center font-bold text-3xl text-black py-12"
            >
              <div> Branding </div>
              <permission-controls
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="branding"
                v-show="MODE == 'permissions'"
              />
            </div>
            <upload-edit-crop
              v-permissions="permissions"
              itemId="branding.logo"
              heading="Logo"
              uploadComponentId="logo-input"
              allowedTypes="jpg/png"
              allowedFileSize="Max File Size 2Mb"
              allowedDimensions="Recommended Width by Height, 267x40px [300dpi]"
              class="pt-12"
              :aspect="fetchCropperConfig('logo', 'aspect')"
              :maxWidth="fetchCropperConfig('logo', 'maxWidth')"
              :maxHeight="fetchCropperConfig('logo', 'maxHeight')"
              :minWidth="fetchCropperConfig('logo', 'minWidth')"
              :minHeight="fetchCropperConfig('logo', 'minHeight')"
              :loadImgUrl="
                formatImageUrl(
                  getValueOrDefaultToMaster('logo.url') ||
                    getValueOrDefaultToMaster('companyLogo.url')
                )
              "
              :loading="logoPhotoLoading"
              :headerClass="classes.header"
              :itemRequired="itemIsRequired('branding.logo')"
              :showEdit="profile.logo && profile.logo.id"
              :imageSrc="profile.logo"
              :fallbackUrl="
                formatImageUrl(
                  getValueOrDefaultToMaster('logo.url') ||
                    getValueOrDefaultToMaster('companyLogo.url')
                )
              "
              @input="controls.updateProfileValueEvent"
              @uploadFile="
                (e) => {
                  croppingImage.url = createObjectURL(e.target.files[0]);
                  croppingImage.key = 'logo';
                  croppingImage.og = createObjectURL(e.target.files[0]);
                  setValueToNull('logo-input');
                  openCropper();
                }
              "
              @imageUpload="
                (e) => {
                  handleImageUpload(e, 'logo');
                }
              "
            >
              <template #permission-controls>
                <permission-controls
                  v-if="MODE == 'permissions'"
                  itemId="branding.logo"
                  @updated="handlePermissionUpdate"
                  :permissions="managePermissions"
                />
              </template>
            </upload-edit-crop>
            <upload-edit-crop
              v-permissions="permissions"
              itemId="branding.banner"
              heading="Background"
              uploadComponentId="banner"
              allowedTypes="jpg/png"
              allowedFileSize="Max File Size 2Mb"
              allowedDimensions="Recommended Width by Height, 1900x800px [300dpi]"
              class="pt-12"
              :aspect="fetchCropperConfig('banner', 'aspect')"
              :maxWidth="fetchCropperConfig('banner', 'maxWidth')"
              :maxHeight="fetchCropperConfig('banner', 'maxHeight')"
              :minWidth="fetchCropperConfig('banner', 'minWidth')"
              :minHeight="fetchCropperConfig('banner', 'minHeight')"
              placeholderUrl="https://res.cloudinary.com/profileme/image/upload/v1688114294/default_background_3973333152.png"
              :loadImgUrl="
                formatImageUrl(getValueOrDefaultToMaster('banner.url'))
              "
              :loading="backgroundPhotoLoading"
              :headerClass="classes.header"
              :itemRequired="itemIsRequired('branding.banner')"
              :showEdit="profile.banner && profile.banner.id"
              :imageSrc="profile.banner"
              :fallbackUrl="
                formatImageUrl(getValueOrDefaultToMaster('banner.url'))
              "
              @input="controls.updateProfileValueEvent"
              @uploadFile="
                (e) => {
                  croppingImage.url = createObjectURL(e.target.files[0]);
                  croppingImage.key = 'banner';
                  croppingImage.og = createObjectURL(e.target.files[0]);
                  setValueToNull('banner');
                  openCropper();
                }
              "
              @imageUpload="
                (e) => {
                  handleImageUpload(e, 'banner');
                }
              "
            >
              <template #permission-controls>
                <permission-controls
                  v-if="MODE == 'permissions'"
                  itemId="branding.banner"
                  @updated="handlePermissionUpdate"
                  :permissions="managePermissions"
                />
              </template>
            </upload-edit-crop>
            <upload-edit-crop
              v-permissions="permissions"
              itemId="branding.mobileBanner"
              heading="Mobile Banner"
              uploadComponentId="mobileBanner"
              allowedTypes="jpg/png/webp/gif/mp4/mov"
              acceptFileType="image/*,video/*"
              allowedFileSize="Max File Size 2Mb"
              allowedDimensions="Recommended Width by Height, 800x507px [300dpi]"
              class="pt-12"
              :aspect="fetchCropperConfig('mobileBanner', 'aspect')"
              :maxWidth="fetchCropperConfig('mobileBanner', 'maxWidth')"
              :maxHeight="fetchCropperConfig('mobileBanner', 'maxHeight')"
              :minWidth="fetchCropperConfig('mobileBanner', 'minWidth')"
              :minHeight="fetchCropperConfig('mobileBanner', 'minHeight')"
              placeholderUrl="https://res.cloudinary.com/profileme/image/upload/v1688114294/default_background_3973333152.png"
              :loadImgUrl="
                formatImageUrl(getValueOrDefaultToMaster('mobileBanner.url'))
              "
              :loading="bannersPhotoLoading.bannerMobile"
              :headerClass="classes.header"
              :itemRequired="itemIsRequired('branding.mobileBanner')"
              :showEdit="
                profile.mobileBanner &&
                profile.mobileBanner.id &&
                !/.(mp4|mov|gif)$/.test(
                  formatImageUrl(getValueOrDefaultToMaster('mobileBanner.url'))
                )
              "
              :imageSrc="profile.mobileBanner"
              :fallbackUrl="
                formatImageUrl(getValueOrDefaultToMaster('mobileBanner.url'))
              "
              @input="controls.updateProfileValueEvent"
              @uploadFile="uploadVideoGifOrCropImage($event, 'mobileBanner')"
              @imageUpload="
                (e) => {
                  handleImageUpload(e, 'mobileBanner');
                }
              "
            >
              <template #permission-controls>
                <permission-controls
                  v-show="MODE == 'permissions'"
                  itemId="branding.mobileBanner"
                  @updated="handlePermissionUpdate"
                  :permissions="managePermissions"
                />
              </template>
            </upload-edit-crop>
            <upload-edit-crop
              v-permissions="permissions"
              itemId="branding.desktopBanner"
              heading="Desktop Banner"
              uploadComponentId="desktopBanner"
              allowedTypes="jpg/png/webp/gif/mp4/mov"
              acceptFileType="image/*,video/*"
              allowedFileSize="Max File Size 2Mb"
              allowedDimensions="Recommended Width by Height, 1280x507px [300dpi]"
              class="pt-12"
              :minWidth="fetchCropperConfig('desktopBanner', 'minWidth')"
              :minHeight="fetchCropperConfig('desktopBanner', 'minHeight')"
              placeholderUrl="https://res.cloudinary.com/profileme/image/upload/v1688114294/default_background_3973333152.png"
              :loadImgUrl="
                formatImageUrl(getValueOrDefaultToMaster('desktopBanner.url'))
              "
              :loading="bannersPhotoLoading.bannerDesktop"
              :headerClass="classes.header"
              :itemRequired="itemIsRequired('branding.desktopBanner')"
              :showEdit="
                profile.desktopBanner &&
                profile.desktopBanner.id &&
                !/.(mp4|mov|gif)$/.test(
                  formatImageUrl(getValueOrDefaultToMaster('desktopBanner.url'))
                )
              "
              :imageSrc="profile.desktopBanner"
              :fallbackUrl="
                formatImageUrl(getValueOrDefaultToMaster('desktopBanner.url'))
              "
              @input="controls.updateProfileValueEvent"
              @uploadFile="uploadVideoGifOrCropImage($event, 'desktopBanner')"
              @imageUpload="
                (e) => {
                  handleImageUpload(e, 'desktopBanner');
                }
              "
            >
              <template #permission-controls>
                <permission-controls
                  v-if="MODE == 'permissions'"
                  itemId="branding.desktopBanner"
                  @updated="handlePermissionUpdate"
                  :permissions="managePermissions"
                />
              </template>
            </upload-edit-crop>
          </div>
        </div>
      </accordion>
    </div>
    <section id="links" />
    <div class="my-6 links" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        itemId="links"
        padding="p-0"
        title="Buttons"
        v-model="sectionAccordions['links']"
        :open="sectionAccordions['links']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="links"
            editId="links.content"
          />
        </template>
        <div
          v-permissions="permissions"
          itemId="links.content"
          class="bg-white p-6 lg:p-12"
        >
          <div v-permissions="permissions" itemId="links.list">
            <draggable
              handle=".drag-handel"
              draggable=".draggable"
              v-bind="dragOptions"
              :list="linksArrayHandler.childArray"
              @change="linksArrayHandler.reOrderChild"
              item-key="id"
            >
              <template #item="{ element }">
                <array-merge-contacts
                  @changeEnabled="
                    linksArrayHandler.updateAtKey(
                      element.key,
                      !element.enabled,
                      'enabled'
                    )
                  "
                  class="mb-5"
                  :class="{
                    draggable: linksArrayHandler.allowOrdering(element.key)
                  }"
                  v-permissions="permissions"
                  :title="element.title"
                  :subtitle="element.url"
                  :link="element.url"
                  :itemId="'links.list.' + element.key"
                  :enabled="element.enabled"
                  :allowDelete="itemIsEditable('links.list.' + element.key)"
                  :allowEdit="itemIsEditable('links.list.' + element.key)"
                  :dragHandel="linksArrayHandler.allowOrdering(element.key)"
                  @removeItem="
                    () => {
                      linksArrayHandler.removeFromChild(element.key);
                      if (element.contactType == 'modal') {
                        popupsArrayHandler.removeFromChild(element.url);
                      }
                    }
                  "
                >
                  <template
                    v-if="element.contactType == 'placeholder'"
                    #default
                  >
                    <div>
                      <p class="text-gray-400 text-2xl mb-5">
                        Placeholder Area (in this area users can add their own
                        custom buttons)
                      </p>
                      <i class="fa-duotone fa-ghost text-8xl"></i>
                    </div>
                  </template>
                  <template
                    v-else-if="itemIsEditable('links.list.' + element.key)"
                    #default
                  >
                    <div class="mt-6 mb-3 grid gap-2">
                      <edit-text
                        v-permissions="permissions"
                        v-model="element.title"
                        emptyString="Title"
                        groupTitle="Links to your info."
                        section="links"
                        :fieldPath="`links[key:${element.key}].title`"
                        :name="`links[${index}].title`"
                        :itemId="'links.list.' + element.key + '.title'"
                        :isRequired="
                          itemIsRequired('links.list.' + element.key + '.title')
                        "
                        :header="true"
                        @change="
                          (value) => {
                            if (
                              popupsArrayHandler.getChildItem({
                                key: element.url
                              })
                            ) {
                              popupsArrayHandler.getChildItem({
                                key: element.url
                              }).title = value;
                            }
                            linksArrayHandler.triggerOnChange(
                              'update',
                              element.key + '.title'
                            );
                          }
                        "
                      >
                        <template #pre-pencil>
                          <span class="text-gray-400 text-3xl md:text-2xl ml-4"
                            >(Title)</span
                          >
                        </template>
                      </edit-text>
                      <edit-text
                        v-if="element.contactType == 'url'"
                        v-model="element.url"
                        v-permissions="permissions"
                        type="url"
                        emptyString="Add Url"
                        groupTitle="Links to your info."
                        section="links"
                        :itemId="'links.list.' + element.key + '.url'"
                        :isRequired="
                          itemIsRequired('links.list.' + element.key + '.url')
                        "
                        :fieldPath="`links[key:${element.key}].url`"
                        :name="`links[${index}].url`"
                        :placeholder="
                          linksArrayHandler.getDefaultFromMasterByKey(
                            element.key,
                            `url`
                          )
                        "
                        @change="
                          linksArrayHandler.triggerOnChange(
                            'update',
                            element.key + '.url'
                          )
                        "
                      >
                        <template #pre-pencil>
                          <span class="text-gray-400 text-3xl md:text-2xl ml-4"
                            >(URL)</span
                          >
                        </template>
                      </edit-text>
                      <div
                        v-else-if="element.contactType == 'modal'"
                        class="mt-2 mb-4"
                      >
                        <pm-button
                          @click="
                            () => {
                              if (
                                popupsArrayHandler.getChildItem({
                                  key: element.url
                                })
                              ) {
                                openModal('modal-popup-editor');
                                controls.popupItem =
                                  popupsArrayHandler.getChildItem({
                                    key: element.url
                                  });
                              }
                            }
                          "
                          compact
                          size="small"
                        >
                          Edit popup content
                        </pm-button>
                      </div>
                    </div>
                    <div class="grid gap-4">
                      <div
                        class="grid mb-8 gap-4 md:gap-12 md:flex md:flex-wrap"
                      >
                        <v-modal title="Choose Icon">
                          <template #trigger="{ open }">
                            <div
                              v-permissions="permissions"
                              :itemId="'links.list.' + element.key + '.icons'"
                              groupId="linkGroupIcons"
                              @click="open"
                              class="text-3xl cursor-pointer"
                            >
                              <i
                                :class="`${
                                  element.icon
                                    ? formatIcon(element.icon)
                                    : 'fa-regular fa-grid-2-plus'
                                } text-4xl mr-2`"
                              ></i>
                              <span class="text-brand-color">(Icon)</span>
                              <span v-show="itemIsRequired('linkGroupIcons')"
                                >*
                              </span>
                            </div>
                          </template>
                          <template #content>
                            <icon-selector
                              :icons="profile.companyIcons"
                              :type="profile.iconStyle"
                              @on-change="
                                () => {
                                  linksArrayHandler.triggerOnChange(
                                    'update',
                                    element.key + '.icon'
                                  );
                                  closeModal('modal-wrapper');
                                  debounceAutoSave();
                                }
                              "
                              v-model="element.icon"
                              @close="closeModal('modal-wrapper')"
                            />
                          </template>
                        </v-modal>
                        <color-picker
                          title="Font & icon"
                          v-permissions="permissions"
                          class="place-content-start flex-row-reverse links-color-picker"
                          :itemId="`links.list.${element.key}.iconColor`"
                          :name="`links.list.${element.key}.iconColor`"
                          :id="`links.list.${element.key}.iconColor`"
                          :defaultColour="
                            getMasterProfileDefault(
                              `contact-button-font-color-${element.key}`
                            )
                          "
                          v-model="
                            profile.cssVariables[
                              `contact-button-font-color-${element.key}`
                            ]
                          "
                          @change="
                            (e) => {
                              linksArrayHandler.triggerOnChange(
                                'update',
                                element.key + '.font-color'
                              );
                              debounceAutoSave(e);
                            }
                          "
                        />
                        <color-picker
                          title="Background"
                          v-permissions="permissions"
                          class="place-content-start flex-row-reverse links-color-picker"
                          :itemId="`links.list.${element.key}.backgroundColor`"
                          :name="`links.list.${element.key}.backgroundColor`"
                          :id="`links.list.${element.key}.backgroundColor`"
                          :defaultColour="
                            getMasterProfileDefault(
                              `contact-button-background-color-${element.key}`
                            )
                          "
                          v-model="
                            profile.cssVariables[
                              `contact-button-background-color-${element.key}`
                            ]
                          "
                          @change="
                            (e) => {
                              linksArrayHandler.triggerOnChange(
                                'update',
                                element.key + '.background-color'
                              );
                              debounceAutoSave(e);
                            }
                          "
                        />
                        <div class="flex">
                          <v-switch
                            v-model="element.isFullWidthButton"
                            :name="`contact-button-${element.key}`"
                            @change="
                              () => {
                                element.class = toggleElementClass(
                                  element.class,
                                  'w-full',
                                  !element.class?.includes('w-full')
                                );
                                debounceAutoSave();
                              }
                            "
                          >
                          </v-switch>
                          <label
                            class="my-auto ml-2 text-3xl"
                            :for="`contact-button-${element.key}`"
                            >Full width</label
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #permissions>
                    <permission-controls
                      v-if="MODE == 'permissions'"
                      @updated="handlePermissionUpdate"
                      :permissions="managePermissions"
                      :itemId="'links.list.' + element.key"
                      :optionalId="'links.list.' + element.key + '[title,url]'"
                      :detail="true"
                    />
                  </template>
                </array-merge-contacts>
              </template>
            </draggable>

            <div
              v-if="linksArrayHandler.isEmpty && itemIsEditable('links')"
              :class="classes.header + ' text-center'"
            >
              No buttons found, please add buttons
            </div>
          </div>
          <div
            v-if="itemIsEditable('links') && itemIsEditable('links.content')"
            v-permissions="permissions"
            class="mx-20 mt-12 box-border"
          >
            <pm-button
              @click="
                () => {
                  openModal('modal-links');
                }
              "
              itemId="links.addMore"
              :class="classes.buttonSize + ' bg-blue text-white'"
              >Add another button</pm-button
            >
          </div>
        </div>
      </accordion>
    </div>
    <div class="my-6 mainActionButton" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        itemId="mainActionButton"
        padding="p-0"
        title="Call to action"
        v-model="sectionAccordions['main-action-button']"
        :open="sectionAccordions['main-action-button']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="mainActionButton"
          />
        </template>
        <section id="main-action-button-ref" />
        <div
          v-permissions="permissions"
          itemId="mainActionButton"
          class="bg-white p-6 lg:p-12"
        >
          <div class="border flex flex-col px-8 py-8">
            <div
              class="grid grid-cols-4 items-center"
              :style="{ 'grid-template-columns': '1fr auto' }"
            >
              <edit-text
                v-permissions="permissions"
                v-model="profile.mainActionButton.title"
                emptyString="Title"
                name="mainActionButton.title"
                section="mainActionButton"
                fieldPath="mainActionButton.title"
                groupTitle="Main action button"
                itemId="mainActionButton.title"
                :isRequired="itemIsRequired('mainActionButton.title')"
                :placeholder="getDefaultFromMaster('mainActionButton.title')"
                :header="true"
                @change="
                  variableRecordHandler.addRecord(
                    'mainActionButton.title',
                    'update'
                  )
                "
              >
                <template #pre-pencil>
                  <span class="text-gray-400 text-3xl md:text-2xl ml-4"
                    >(Title)</span
                  >
                </template>
              </edit-text>
              <permission-controls
                class="grid-inline"
                v-show="MODE == 'permissions'"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="mainActionButton.title"
              />
            </div>
            <section id="cto" />
            <div
              class="grid grid-cols-2 gap-4 items-center"
              :style="{ 'grid-template-columns': '1fr auto' }"
              :class="{ 'mt-8': MODE == 'permissions' }"
            >
              <edit-text
                v-permissions="permissions"
                v-model="profile.mainActionButton.url"
                type="url"
                emptyString="Add Url"
                name="mainActionButton.url"
                itemId="mainActionButton.url"
                section="mainActionButton"
                fieldPath="mainActionButton.url"
                groupTitle="Main action button"
                :isRequired="itemIsRequired('mainActionButton.url')"
                :placeholder="getDefaultFromMaster('mainActionButton.url')"
                @change="
                  variableRecordHandler.addRecord(
                    'mainActionButton.url',
                    'update'
                  )
                "
              >
                <template #pre-pencil>
                  <span class="text-gray-400 text-3xl md:text-2xl ml-4"
                    >(URL)</span
                  >
                </template>
              </edit-text>
              <permission-controls
                v-show="MODE == 'permissions'"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="mainActionButton.url"
              />
            </div>
            <div class="flex items-center justify-between mt-8">
              <v-modal title="Choose Icon">
                <template #trigger="{ open }">
                  <div
                    v-permissions="permissions"
                    itemId="mainActionButton.icon"
                    @click="open"
                    class="mt-2 cursor-pointer"
                    :class="classes.blueHeader"
                  >
                    <i
                      :class="`${
                        profile.mainActionButton?.icon
                          ? formatIcon(profile.mainActionButton.icon)
                          : 'fa-regular fa-grid-2-plus'
                      } text-4xl mr-2`"
                    ></i>
                    <span class="text-brand-color"> (Icon) </span>
                    <span v-show="itemIsRequired('mainActionButton.icon')"
                      >*
                    </span>
                  </div>
                </template>
                <template #content>
                  <icon-selector
                    :icons="profile.companyIcons"
                    :type="profile.iconStyle"
                    @on-change="
                      (icon) => {
                        profile.mainActionButton.icon = icon;
                        variableRecordHandler.addRecord(
                          'mainActionButton.icon',
                          'update'
                        );
                        closeModal('modal-wrapper');
                        debounceAutoSave();
                      }
                    "
                    v-model="profile.mainActionButton.icon"
                    @close="closeModal('modal-wrapper')"
                  />
                </template>
              </v-modal>
              <permission-controls
                v-show="MODE == 'permissions'"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="mainActionButton.icon"
              />
            </div>
            <div class="flex items-center justify-between mt-10">
              <color-picker
                v-permissions="permissions"
                itemId="mainActionButton.backgroundColor"
                class="mr-10 -ml-4 flex-row-reverse"
                name="mainActionButtonBackgroundColor"
                id="main-action-button-background-color"
                title="Pick background colour"
                :defaultColour="
                  getMasterProfileDefault('main-action-button-background-color')
                "
                v-model="
                  profile.cssVariables['main-action-button-background-color']
                "
                @change="
                  (e) => {
                    variableRecordHandler.addRecord(
                      'mainActionButton.backgroundColor',
                      'update'
                    );
                    debounceAutoSave(e);
                  }
                "
              />
              <permission-controls
                v-show="MODE == 'permissions'"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="mainActionButton.backgroundColor"
              />
            </div>
            <div class="flex items-center justify-between mt-10">
              <color-picker
                v-permissions="permissions"
                itemId="mainActionButton.color"
                class="mr-10 -ml-4 flex-row-reverse"
                name="mainActionButtonColor"
                title="Pick font & icon colour"
                id="main-action-button-color"
                :defaultColour="
                  getMasterProfileDefault('main-action-button-color')
                "
                v-model="profile.cssVariables['main-action-button-color']"
                @change="
                  (e) => {
                    variableRecordHandler.addRecord(
                      'mainActionButton.color',
                      'update'
                    );
                    debounceAutoSave(e);
                  }
                "
              />
              <permission-controls
                v-show="MODE == 'permissions'"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="mainActionButton.color"
              />
            </div>
          </div>
        </div>
      </accordion>
    </div>
    <div class="my-6 infoSections" v-if="isDesktopTemplate">
      <accordion
        padding="p-0"
        :title="itemIsEditable('infoSections') ? 'Drop-down sections' : ''"
        v-model="sectionAccordions['infoSections']"
        :open="
          sectionAccordions['infoSections'] || !itemIsEditable('infoSections')
        "
      >
        <template #controls>
          <permission-controls
            v-if="MODE == 'permissions'"
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="infoSections"
            editId="infoSections"
          />
        </template>
        <div :class="{ 'p-5 bg-white': itemIsEditable('infoSections') }">
          <draggable
            v-model="sectionHandler.state.infoItems"
            handle=".drag-handel"
            v-show="sectionHandler.state.infoItems?.length > 0"
            v-bind="dragOptions"
            @change="debounceAutoSave()"
            item-key="id"
          >
            <template #item="{ element: infoItem }">
              <div class="my-6" :class="[infoItem.key, infoItem.type]">
                <accordion
                  v-if="itemIsShown(infoItem.permissionKey)"
                  v-model="sectionAccordions[infoItem.key]"
                  padding="p-0"
                  class="mb-5"
                  :titleBackground="
                    itemIsEditable('infoSections')
                      ? 'bg-gray-100'
                      : 'bg-gray-200'
                  "
                  :itemId="infoItem.permissionKey"
                  :title="
                    sectionHandler.infoWrapper.getFromChildOrMaster(
                      infoItem.key,
                      'title'
                    )
                  "
                  :key="infoItem.key"
                  :open="sectionAccordions[infoItem.key]"
                >
                  <template #pre-title>
                    <vertical-dots
                      v-if="itemIsEditable('infoSections')"
                      class="w-16 h-12 drag-handel"
                    />
                  </template>
                  <template
                    v-if="
                      (itemIsEditable(infoItem.permissionKey) &&
                        itemIsShown('infoSections.add') &&
                        itemIsEditable('infoSections')) ||
                      MODE == 'permissions'
                    "
                    #chevron
                  >
                    <permission-controls
                      @click="stopProp"
                      v-if="MODE == 'permissions'"
                      class="mr-2"
                      @updated="handlePermissionUpdate"
                      :permissions="managePermissions"
                      :showId="infoItem.permissionKey"
                      :editId="infoItem.permissionKey + '.content'"
                    />
                    <eye-switch
                      class="mr-4"
                      v-model="infoItem.info.enabled"
                      @on-change="debounceAutoSave()"
                      @click="stopProp"
                    />
                    <v-modal
                      v-if="
                        itemIsEditable(infoItem.permissionKey) &&
                        itemIsShown('infoSections.add') &&
                        itemIsEditable('infoSections')
                      "
                      @click="stopProp"
                      class="flex-1 mr-8"
                      :modalName="infoItem.key"
                      :title="'Are you sure you want to delete this section?'"
                    >
                      <template #trigger="{ open }">
                        <div class="text-3xl cursor-pointer" @click="open">
                          <i
                            class="p-2 fa-light fa-trash-can icon-gray hover-icon-red"
                          ></i>
                        </div>
                      </template>
                      <template #content>
                        <confirm-dialog
                          message="Are you sure you want to delete this section, all items underneath this section will also be deleted."
                          @confirm="
                            () => sectionHandler.removeAtKey(infoItem.key)
                          "
                          @cancel="closeModal('modal-wrapper')"
                        />
                      </template>
                    </v-modal>
                  </template>
                  <div class="flex flex-col bg-white p-6 lg:p-12">
                    <div
                      v-permissions="permissions"
                      :itemId="infoItem.permissionKey + '.sectionControls'"
                      class="section-editor flex justify-between items-center border mb-8 py-8 px-6"
                      v-if="
                        itemIsEditable(infoItem.permissionKey) &&
                        itemIsEditable('infoSections')
                      "
                    >
                      <div class="flex items-center justify-between mr-16">
                        <v-modal>
                          <template #trigger="{ open }">
                            <div
                              @click="open"
                              class="text-3xl cursor-pointer text-center"
                            >
                              <span>
                                <i
                                  class="p-2"
                                  :class="
                                    formatIcon(infoItem.info.icon) ||
                                    'fa-regular fa-grid-2-plus'
                                  "
                                ></i>
                                <span class="text-brand-color"> (Icon) </span>
                              </span>
                            </div>
                          </template>
                          <template #content>
                            <icon-selector
                              :icons="profile.companyIcons"
                              :type="profile.iconStyle"
                              @on-change="
                                (icon) => {
                                  infoItem.info.icon = icon;
                                  sectionHandler.infoWrapper.triggerOnChange(
                                    'update',
                                    infoItem.permissionKey + '.icon'
                                  );
                                  closeModal('modal-wrapper');
                                  debounceAutoSave();
                                }
                              "
                              v-model="infoItem.info.icon"
                              @close="closeModal('modal-wrapper')"
                            />
                          </template>
                        </v-modal>
                      </div>

                      <div class="flex justify-between flex-1">
                        <edit-text
                          v-model="infoItem.info.title"
                          type="text"
                          emptyString="Add title"
                          @change="
                            sectionHandler.infoWrapper.triggerOnChange(
                              'update',
                              infoItem.permissionKey + '.title'
                            )
                          "
                        />
                      </div>
                      <permission-controls
                        v-if="MODE == 'permissions'"
                        @click="stopProp"
                        @updated="handlePermissionUpdate"
                        :permissions="managePermissions"
                        :editId="infoItem.permissionKey"
                      />
                    </div>
                    <div
                      v-if="
                        infoItem.type == 'testimonials' && !profile.isMaster
                      "
                      :permissions="managePermissions"
                      :itemId="infoItem.permissionKey + '.content'"
                    >
                      <draggable
                        handle=".drag-handel"
                        direction="horizontal"
                        item-key="id"
                        :itemId="infoItem.key + '.list'"
                        v-model="profile.testimonials"
                        v-bind="dragOptions"
                        v-permissions="permissions"
                      >
                        <template #item="{ element, index }">
                          <v-input-wrapper
                            v-permissions="permissions"
                            :itemId="'testimonials.list' + element.key"
                            class="border"
                            :class="{ 'mt-12': index }"
                          >
                            <div class="relative flex items-center">
                              <!-- drag handel -->
                              <div class="grab px-2 drag-handel">
                                <vertical-dots class="w-16 h-12" />
                              </div>
                              <v-testimonials
                                class="flex-1 md:pr-10"
                                @remove="
                                  removeArrayItemByKey(
                                    'testimonials',
                                    element.key
                                  )
                                "
                                v-model="profile.testimonials[index]"
                                :classes="classes"
                                @input="
                                  debounceAutoSave({
                                    target: { name: 'testimonials' }
                                  })
                                "
                              />
                            </div>
                          </v-input-wrapper>
                        </template>
                      </draggable>
                      <div class="box-border">
                        <pm-button
                          @click="addTestimonials(infoItem.key)"
                          class="mt-12"
                          >Add testimonial</pm-button
                        >
                      </div>
                    </div>
                    <div
                      v-else-if="infoItem.type == 'imageGallery'"
                      :permissions="managePermissions"
                      :itemId="infoItem.permissionKey + '.content'"
                    >
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        class="text-black text-2xl mb-8"
                      >
                        Format: jpg, gif, png. Maximum file size: 2MB. Maximum
                        files:
                        {{ getImageGalleryLimit }}
                      </div>
                      <draggable
                        handle=".drag-handel"
                        direction="horizontal"
                        item-key="id"
                        :itemId="infoItem.permissionKey + '.list'"
                        :list="infoItem.itemsArrayWrapper.childArray"
                        v-bind="dragOptions"
                        v-permissions="permissions"
                        @change="infoItem.itemsArrayWrapper.reOrderChild"
                      >
                        <template #item="{ element: imageItem }">
                          <array-merge-item
                            @changeEnabled="
                              infoItem.itemsArrayWrapper.updateAtKey(
                                imageItem.key,
                                !imageItem.enabled,
                                'enabled'
                              )
                            "
                            @click="
                              () => {
                                editPicture(
                                  imageItem,
                                  infoItem.key,
                                  itemIsEditable(
                                    infoItem.permissionKey +
                                      '.list.' +
                                      imageItem.key
                                  ) &&
                                    itemIsEditable(
                                      infoItem.permissionKey + '.content'
                                    )
                                );
                              }
                            "
                            v-permissions="permissions"
                            class="mb-5 cursor-pointer"
                            :title="imageItem.title"
                            :subtitle="imageItem.link"
                            :image="formatImageThumbnail(imageItem.url)"
                            :link="imageItem.link"
                            :itemId="
                              infoItem.permissionKey + '.list.' + imageItem.key
                            "
                            :enabled="imageItem.enabled"
                            :allowDelete="
                              itemIsEditable(
                                infoItem.permissionKey + '.content'
                              )
                            "
                            :allowEdit="
                              itemIsEditable(
                                infoItem.permissionKey +
                                  '.list.' +
                                  imageItem.key
                              ) &&
                              itemIsEditable(
                                infoItem.permissionKey + '.content'
                              )
                            "
                            @removeItem="
                              infoItem.itemsArrayWrapper.removeFromChild(
                                imageItem.key
                              )
                            "
                          >
                            <template #permissions>
                              <permission-controls
                                v-if="MODE == 'permissions'"
                                @updated="handlePermissionUpdate"
                                :permissions="managePermissions"
                                :showId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  imageItem.key
                                "
                                :editId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  imageItem.key
                                "
                                :detail="true"
                              />
                            </template>
                          </array-merge-item>
                        </template>
                      </draggable>
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        v-show="
                          infoItem.itemsArrayWrapper.childArray.length <= 0
                        "
                        class="font-bold text-4xl md:text-3xl text-black py-2 text-center mt-6"
                      >
                        No Photos found, please add photos
                      </div>
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        class="mt-12 mx-20 box-border"
                      >
                        <pm-button
                          @click="
                            () => {
                              imageGalleryItem = {
                                type: infoItem.key
                              };
                              openModal('modal-photo-gallery');
                            }
                          "
                        >
                          Add another photo
                        </pm-button>
                      </div>
                    </div>
                    <div
                      v-else-if="infoItem.type == 'videoGallery'"
                      :permissions="managePermissions"
                      :itemId="infoItem.permissionKey + '.content'"
                    >
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        class="mb-8"
                        :class="classes.subHeader"
                      >
                        Please supply YouTube links only
                      </div>
                      <draggable
                        handle=".drag-handel"
                        v-bind="dragOptions"
                        :itemId="infoItem.permissionKey + '.list'"
                        @change="infoItem.itemsArrayWrapper.reOrderChild"
                        :list="infoItem.itemsArrayWrapper.childArray"
                        item-key="id"
                      >
                        <template #item="{ element: videoItem }">
                          <array-merge-item
                            @changeEnabled="
                              infoItem.itemsArrayWrapper.updateAtKey(
                                videoItem.key,
                                !videoItem.enabled,
                                'enabled'
                              )
                            "
                            class="mb-5"
                            v-permissions="permissions"
                            :subtitle="videoItem.url"
                            :link="videoItem.url"
                            :image="convertURLToImagePreview(videoItem.url)"
                            :itemId="
                              infoItem.permissionKey + '.list.' + videoItem.key
                            "
                            :enabled="videoItem.enabled"
                            :allowDelete="
                              itemIsEditable(
                                infoItem.permissionKey + '.content'
                              )
                            "
                            :allowEdit="
                              itemIsEditable(
                                infoItem.permissionKey +
                                  '.list.' +
                                  videoItem.key
                              ) &&
                              itemIsEditable(
                                infoItem.permissionKey + '.content'
                              )
                            "
                            @removeItem="
                              infoItem.itemsArrayWrapper.removeFromChild(
                                videoItem.key
                              )
                            "
                          >
                            <template
                              v-if="
                                itemIsEditable(
                                  infoItem.permissionKey + '.content'
                                )
                              "
                              #default
                            >
                              <edit-text
                                v-permissions="permissions"
                                v-model="videoItem.title"
                                :itemId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  videoItem.key +
                                  '.title'
                                "
                                :name="`videoGallery[${index}].title`"
                                emptyString="Add title"
                                @change="
                                  sectionHandler.infoWrapper.triggerOnChange(
                                    'update',
                                    infoItem.permissionKey +
                                      '.list.' +
                                      videoItem.key +
                                      '.title'
                                  )
                                "
                              />
                              <edit-text
                                v-permissions="permissions"
                                v-model="videoItem.url"
                                :itemId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  videoItem.key +
                                  '.url'
                                "
                                :name="`videoGallery[${index}].url`"
                                type="url"
                                emptyString="Add Url"
                                @change="
                                  sectionHandler.infoWrapper.triggerOnChange(
                                    'update',
                                    infoItem.permissionKey +
                                      '.list.' +
                                      videoItem.key +
                                      '.url'
                                  )
                                "
                              />
                            </template>
                            <template #permissions>
                              <permission-controls
                                v-if="MODE == 'permissions'"
                                @updated="handlePermissionUpdate"
                                :permissions="managePermissions"
                                :showId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  videoItem.key
                                "
                                :editId="
                                  infoItem.permissionKey +
                                  '.list.' +
                                  videoItem.key
                                "
                                :detail="true"
                              />
                            </template>
                          </array-merge-item>
                        </template>
                      </draggable>
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        v-show="infoItem.itemsArrayWrapper.isEmpty"
                        :class="classes.header + ' text-center'"
                      >
                        No videos links found, please add links
                      </div>
                      <div
                        v-if="
                          itemIsEditable(infoItem.permissionKey + '.content')
                        "
                        class="mt-12 mx-20 box-border"
                      >
                        <pm-button
                          @click="addAnotherVideo(infoItem.type, infoItem.key)"
                          >Add another video</pm-button
                        >
                      </div>
                    </div>
                    <div
                      v-else-if="infoItem.type == 'aboutme'"
                      v-permissions="permissions"
                      :itemId="infoItem.permissionKey + '.content'"
                      class="bg-white"
                    >
                      <div class="font-bold text-3xl text-black pb-12">
                        Your 'About me' information
                      </div>
                      <div
                        v-permissions="permissions"
                        :itemId="infoItem.permissionKey + '.aboutMeVideo'"
                        class="flex items-center relative flex-wrap"
                      >
                        <v-input
                          v-model="profile.aboutMeVideo"
                          name="aboutmevideo"
                          class="flex-1"
                          type="url"
                          :mode="'hidden'"
                          :placeholderClasses="classes.placeholder"
                          :inputClasses="classes.inputs"
                          :placeholder="getDefaultFromMaster('aboutMeVideo')"
                          @change="
                            sectionHandler.infoWrapper.triggerOnChange(
                              'update',
                              infoItem.permissionKey + '.aboutMeVideo'
                            )
                          "
                        >
                          Your 'About me' video<span
                            class="ml-1 text-3xl text-red-600"
                            v-show="
                              itemIsRequired(
                                infoItem.permissionKey + '.aboutMeVideo'
                              )
                            "
                            >*
                          </span>
                        </v-input>
                        <div class="ml-8 relative bottom-4">
                          <eye-switch
                            v-show="MODE !== 'permissions'"
                            v-model="profile.aboutMeVideoEnabled"
                            @on-change="
                              debounceAutoSave({
                                target: {
                                  name: 'aboutMeVideoEnabled',
                                  value: profile.aboutMeVideoEnabled
                                }
                              })
                            "
                          />
                        </div>
                        <permission-controls
                          @updated="handlePermissionUpdate"
                          :permissions="managePermissions"
                          :itemId="infoItem.permissionKey + '.aboutMeVideo'"
                          class="absolute right-0 top-8"
                          v-show="MODE == 'permissions'"
                        />
                        <a
                          class="text-2xl w-full mb-8 md:text-xl"
                          target="_blank"
                          :href="profile.aboutMeVideoHelperLink"
                        >
                          Click here to access the “About me” video
                          requirements</a
                        >
                      </div>
                      <div
                        v-permissions="permissions"
                        :itemId="infoItem.key + '.aboutMeDescription'"
                        :fieldPath="`infoSections[key:${infoItem.key}].description`"
                        :name="infoItem.key + '.aboutMeDescription'"
                        :section="infoItem.key"
                        :groupTitle="infoItem.info.title"
                      >
                        <div
                          class="font-bold text-2xl text-black pb-12 flex justify-between"
                        >
                          <p
                            >Your 'About me' description<span
                              class="ml-1 text-3xl text-red-600"
                              v-show="
                                itemIsRequired(
                                  infoItem.permissionKey + '.aboutMeDescription'
                                )
                              "
                              >*
                            </span></p
                          >
                          <permission-controls
                            @updated="handlePermissionUpdate"
                            :permissions="managePermissions"
                            :itemId="
                              infoItem.permissionKey + '.aboutMeDescription'
                            "
                            v-show="MODE == 'permissions'"
                          />
                        </div>
                        <div v-if="hasRte">
                          <v-quill
                            :profileId="profileId"
                            name="aboutMeRte"
                            v-model="infoItem.info.description"
                            @change="
                              (e) => {
                                sectionHandler.infoWrapper.triggerOnChange(
                                  'update',
                                  infoItem.permissionKey + '.aboutMeDescription'
                                );
                                debounceAutoSave(e);
                              }
                            "
                          />
                        </div>
                        <v-text-area
                          v-else
                          :placeholder="
                            sectionHandler.infoWrapper.getDefaultFromMasterByKey(
                              infoItem.key,
                              'description'
                            )
                          "
                          v-model="infoItem.info.description"
                          @change="
                            sectionHandler.infoWrapper.triggerOnChange(
                              'update',
                              infoItem.permissionKey + '.aboutMeDescription'
                            )
                          "
                        />
                      </div>
                    </div>
                    <div
                      v-else-if="infoItem.type == 'text'"
                      v-permissions="permissions"
                      :itemId="infoItem.permissionKey + '.content'"
                      :name="infoItem.key + '.description'"
                      :section="infoItem.key"
                      :fieldPath="`infoSections[key:${infoItem.key}].description`"
                      :groupTitle="infoItem.info.title"
                    >
                      <div
                        class="font-bold text-2xl text-black pb-12 flex justify-between"
                      >
                        <p
                          >Your '{{ infoItem.info.title }}' description<span
                            class="ml-1 text-3xl text-red-600"
                            v-show="
                              itemIsRequired(
                                infoItem.permissionKey + '.description'
                              )
                            "
                            >*
                          </span></p
                        >
                        <permission-controls
                          @updated="handlePermissionUpdate"
                          :permissions="managePermissions"
                          :itemId="infoItem.permissionKey + '.description'"
                          v-show="MODE == 'permissions'"
                        />
                      </div>
                      <div
                        v-permissions="permissions"
                        :itemId="infoItem.permissionKey + '.description'"
                      >
                        <div v-if="hasRte">
                          <v-quill
                            :profileId="profileId"
                            :name="`${infoItem.item?.key || 'unknown'}Rte`"
                            v-model="infoItem.info.description"
                            @change="
                              (e) => {
                                sectionHandler.infoWrapper.triggerOnChange(
                                  'update',
                                  infoItem.permissionKey + '.description'
                                );
                                debounceAutoSave(e);
                              }
                            "
                          />
                        </div>
                        <v-text-area
                          v-else
                          :placeholder="
                            sectionHandler.infoWrapper.getDefaultFromMasterByKey(
                              infoItem.key,
                              'description'
                            )
                          "
                          v-model="infoItem.info.description"
                          @change="
                            sectionHandler.infoWrapper.triggerOnChange(
                              'update',
                              infoItem.permissionKey + '.description'
                            )
                          "
                        />
                      </div>
                    </div>
                  </div>
                </accordion>
              </div>
            </template>
          </draggable>
          <div
            v-if="itemIsEditable('infoSections')"
            v-permissions="permissions"
            itemId="infoSections.add"
            class="mt-12 flex items-center"
          >
            <pm-button
              class="success box-border"
              @click="
                () => {
                  openModal('modal-section');
                }
              "
              >Add section</pm-button
            >
            <permission-controls
              v-if="MODE == 'permissions'"
              showId="infoSections.add"
              class="px-2"
              :permissions="managePermissions"
              :detail="false"
              @updated="handlePermissionUpdate"
            />
          </div>
        </div>
      </accordion>
    </div>
    <div class="my-6 qr-code">
      <accordion
        v-permissions="permissions"
        itemId="QRCode"
        padding="p-0"
        title="QR Code"
        v-model="sectionAccordions['qr-code']"
        :open="sectionAccordions['qr-code']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="QRCode"
            editId="QRCode.content"
          />
        </template>
        <div class="bg-white p-6 lg:p-12">
          <div v-if="isQrCodeUploaded" class="grid">
            <img
              :src="formatImageUrl(profile.qrcode.url)"
              class="pb-8 w-qrcode m-auto"
            />
            <a
              download
              :href="formatImageUrl(profile.qrcode.url)"
              class="hidden"
            />
            <div class="flex gap-4">
              <pm-button
                v-show="!controls.profile.isMaster"
                compact
                class="m-auto w-90"
                @click="downloadQR"
                >Download</pm-button
              >
            </div>
          </div>
          <GenerateQrCode
            v-else-if="!isQrCodeUploaded"
            :url="
              profile.domain?.domainName
                ? getProfileUrl()
                : domainAliasForQrCode
            "
            :modelValue="profile.qrCodeData || profile.masterProfile.qrCodeData"
            :image="
              getValueOrDefaultToMaster('logo.url') ||
              getValueOrDefaultToMaster('companyLogo.url')
            "
            :editable="itemIsEditable('QRCode.content')"
            @change="
              (o) => {
                profile.qrCodeData = o;
                variableRecordHandler.addRecord('QRCode.content', 'update');
                debounceAutoSave();
              }
            "
          ></GenerateQrCode>
        </div>
      </accordion>
    </div>
    <section id="social-media" />
    <div class="my-6 contact-form"></div>
    <div class="my-6 social-media" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        itemId="socialMedia"
        padding="p-0"
        title="Social media"
        v-model="sectionAccordions['social-media']"
        :open="sectionAccordions['social-media']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="socialMedia"
          />
        </template>
        <div
          v-permissions="permissions"
          itemId="socialMedia.content"
          class="bg-white p-6 lg:p-12"
        >
          <draggable
            v-permissions="permissions"
            itemId="socialMedia.list"
            handle=".drag-handel"
            v-bind="dragOptions"
            @change="socialMediaArrayHandler.reOrderChild"
            :list="socialMediaArrayHandler.childArray"
            item-key="id"
          >
            <template #item="{ element, index }">
              <array-merge-item
                @changeEnabled="
                  socialMediaArrayHandler.updateAtKey(
                    element.key,
                    !element.enabled,
                    'enabled'
                  )
                "
                v-permissions="permissions"
                :itemId="'socialMedia.list.' + element.key"
                :class="`${index === 0 ? '' : 'mt-12'}`"
                :enabled="element.enabled"
                :allowEdit="itemIsEditable('socialMedia.list.' + element.key)"
                :title="element.title"
                :subtitle="element.url"
                :link="element.url"
                @removeItem="
                  socialMediaArrayHandler.removeFromChild(element.key)
                "
                @change="debounceAutoSave"
              >
                <template
                  v-if="itemIsEditable('socialMedia.list.' + element.key)"
                  #default
                >
                  <edit-text
                    v-model="element.url"
                    v-permissions="permissions"
                    groupTitle="Social media"
                    section="social-media"
                    item-key="id"
                    type="url"
                    :fieldPath="`socialMedia[key:${element.key}].url`"
                    :itemId="'socialMedia.list.' + element.key + '.url'"
                    :isRequired="
                      itemIsRequired('socialMedia.list.' + element.key + '.url')
                    "
                    :inputPlaceholder="'https://example.com/username'"
                    :name="`socialLinks[${index}].url`"
                    :placeholder="
                      socialMediaArrayHandler.getDefaultFromMasterByKey(
                        element.key,
                        'url'
                      )
                    "
                    emptyString="Copy and paste full url here"
                    @change="
                      variableRecordHandler.addRecord(
                        'socialMedia.list.' + element.key + '.url',
                        'update'
                      )
                    "
                  />
                  <div class="flex text-3xl mt-2">
                    <i :class="`${element.icon} text-4xl mr-2`"></i>
                    ({{ element.title }})
                  </div>
                </template>
                <template #permissions>
                  <permission-controls
                    v-if="MODE == 'permissions'"
                    @updated="handlePermissionUpdate"
                    :permissions="managePermissions"
                    :itemId="'socialMedia.list.' + element.key"
                    :optionalId="'socialMedia.list.' + element.key + '.url'"
                    :detail="true"
                  />
                </template>
              </array-merge-item>
            </template>
          </draggable>
          <div
            v-show="socialMediaArrayHandler.isEmpty"
            :class="classes.header + ' text-center'"
          >
            No social links found, please add links
          </div>
          <v-modal
            v-permissions="permissions"
            itemId="socialMedia.addMore"
            title="Add Social Media"
          >
            <template #trigger="{ open }">
              <div @click="open" class="mt-12 mx-20 box-border">
                <pm-button>Add another social platform</pm-button>
              </div>
            </template>
            <template #content>
              <social-media-selector
                :items="socialMediaArrayHandler.childArray"
                :socialOptions="profile.companySocials"
                @close="closeModal('modal-wrapper')"
                @selected="
                  (e) => {
                    addAnotherSocial(e);
                    closeModal('modal-wrapper');
                  }
                "
              />
            </template>
          </v-modal>
        </div>
      </accordion>
    </div>
    <div class="my-6 disclaimer" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        padding="p-0"
        title="Footer disclaimer"
        itemId="profileDisclaimer"
        v-model="sectionAccordions['footer-disclaimer']"
        :open="sectionAccordions['footer-disclaimer']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="profileDisclaimer"
          />
        </template>
        <div
          class="bg-white py-4 px-8 pt-8"
          v-permissions="permissions"
          itemId="profileDisclaimer.content"
        >
          <div>
            <div
              class="font-bold text-2xl text-black mb-8 flex justify-between"
            >
              <p>
                Your footers disclaimer.
                <span
                  class="ml-1 text-3xl text-red-600"
                  v-show="itemIsRequired('profileDisclaimer.value')"
                  >*
                </span>
              </p>
              <permission-controls
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="profileDisclaimer.value"
                v-show="MODE == 'permissions'"
              />
            </div>
            <v-quill
              name="profileDisclaimer"
              itemId="profileDisclaimer.value"
              fieldPath="profileDisclaimer"
              section="footer-disclaimer"
              groupTitle="Footer disclaimer."
              v-permissions="permissions"
              v-model="profile.profileDisclaimer"
              :initial="getDefaultFromMaster('profileDisclaimer')"
              :profileId="profileId"
              :placeholder="
                getDefaultFromMaster('profileDisclaimer') || 'Footer disclaimer'
              "
              :toolbar="[
                'bold',
                'italic',
                'underline',
                'link',
                { script: 'super' },
                { script: 'sub' }
              ]"
              @change="
                (e) => {
                  variableRecordHandler.addRecord('profileDisclaimer.value');
                  debounceAutoSave(e);
                }
              "
            />
          </div>
        </div>
      </accordion>
    </div>
    <div class="my-6 referral_message" v-if="isDesktopTemplate">
      <accordion
        v-permissions="permissions"
        padding="p-0"
        title="Custom share message"
        itemId="referral_message"
        v-model="sectionAccordions['referral_message']"
        :open="sectionAccordions['referral_message']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="referral_message"
          />
        </template>
        <div
          class="bg-white py-4 px-8 pt-8"
          v-permissions="permissions"
          itemId="referral_message.content"
        >
          <div>
            <div
              class="font-bold text-2xl text-black mb-8 flex justify-between"
            >
              <p>
                <b>Add your custom WhatsApp share message</b> (this message will
                show when your profile is shared via WhatsApp)
                <span
                  class="ml-1 text-3xl text-red-600"
                  v-show="itemIsRequired('referral_message.content')"
                  >*
                </span>
              </p>
              <permission-controls
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="referral_message.content"
                v-show="MODE == 'permissions'"
              />
            </div>
            <v-text-area
              v-permissions="permissions"
              v-model="profile.referral_message"
              itemId="referral_message.content"
              fieldPath="referral_message"
              section="referral_message"
              groupTitle="Share Message."
              :placeholder="
                getDefaultFromMaster('referral_message') ||
                'E.g.. I take care of what you value most, let\'s connect'
              "
              @change="
                variableRecordHandler.addRecord('referral_message.content')
              "
            />
          </div>
        </div>
      </accordion>
    </div>
    <div class="my-6 cssPlug">
      <accordion
        v-if="hasStyling"
        v-permissions="permissions"
        v-model="sectionAccordions['cssPlug']"
        padding="p-0"
        title="CSS Plugin"
        itemId="cssPlug"
        :open="sectionAccordions['cssPlug']"
      >
        <template #controls v-if="MODE == 'permissions'">
          <permission-controls
            class="mr-2"
            @updated="handlePermissionUpdate"
            :permissions="managePermissions"
            showId="cssPlug"
          />
        </template>
        <div
          class="bg-white py-4 px-8 pt-8"
          v-permissions="permissions"
          itemId="cssPlug.content"
        >
          <div class="flex pb-6 justify-center items-center bg-white">
            <p class="truncate w-0 flex-1 text-3xl text-black">
              Preview:
              <a class="underline" target="_blank" :href="previewLink">{{
                previewLink
              }}</a>
            </p>
            <i
              @click="copy(previewLink)"
              class="fa-regular fa-clone cursor-pointer text-5xl mx-12 text-gray-400"
            ></i>
            <permission-controls
              @updated="handlePermissionUpdate"
              :permissions="managePermissions"
              itemId="cssPlug.content"
              v-show="MODE == 'permissions'"
            />
          </div>
          <accordion
            v-if="allowTemplateEdit"
            padding="p-0"
            class="mb-5"
            titleBackground="bg-gray-100"
            itemId="cssPlug.globalStyling"
            title="Global Styling"
          >
            <template v-if="MODE == 'permissions'" #chevron>
              <permission-controls
                @click="stopProp"
                v-if="MODE == 'permissions'"
                class="mr-2"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="cssPlug.globalStyling"
              />
            </template>
            <div class="p-10">
              <v-css-editor
                v-model="profile.stylingTemplateCssPlug"
                @input="
                  (e) => {
                    e.stopPropagation();
                  }
                "
                @change="
                  (e) => {
                    variableRecordHandler.addRecord('cssPlug.globalStyling');
                    debounceAutoSave(e);
                  }
                "
              />
            </div>
          </accordion>
          <accordion
            padding="p-0"
            class="mb-5"
            titleBackground="bg-gray-100"
            itemId="cssPlug.customStyling"
            title="Custom Styling"
          >
            <template v-if="MODE == 'permissions'" #chevron>
              <permission-controls
                @click="stopProp"
                v-if="MODE == 'permissions'"
                class="mr-2"
                @updated="handlePermissionUpdate"
                :permissions="managePermissions"
                itemId="cssPlug.customStyling"
              />
            </template>
            <div class="p-10">
              <v-css-editor
                v-model="profile.cssPlug"
                @input="
                  (e) => {
                    e.stopPropagation();
                  }
                "
                @change="
                  (e) => {
                    variableRecordHandler.addRecord('cssPlug.customStyling');
                    debounceAutoSave(e);
                  }
                "
              />
            </div>
          </accordion>
        </div>
      </accordion>
    </div>
    <div class="modals">
      <!-- CROPPER -->
      <cropper
        ref="imageCropper"
        :aspect="fetchCropperConfig(croppingImage.key, 'aspect')"
        :maxWidth="fetchCropperConfig(croppingImage.key, 'maxWidth')"
        :maxHeight="fetchCropperConfig(croppingImage.key, 'maxHeight')"
        :minWidth="fetchCropperConfig(croppingImage.key, 'minWidth')"
        :minHeight="fetchCropperConfig(croppingImage.key, 'minHeight')"
        v-model="croppingImage"
        @cropped="
          () => {
            handleImageUpload(croppingImage, croppingImage.key);
          }
        "
      ></cropper>
      <!-- Publish Warning Message -->
      <v-modal
        title="Edit popup"
        :modalName="'modal-popup-editor'"
        classes="modal-content default-overflow"
        @close="popupItem = {}"
      >
        <template #content>
          <div class="px-8 py-2 rteModalSection">
            <h1 :class="['mx-2 mb-10', classes.blueHeader]">
              {{ controls.popupItem.title }}
            </h1>
            <div v-if="hasRte">
              <v-quill
                :enableScrollContainerHtml="false"
                class="rteModalEditContainer"
                :name="`${controls.popupItem.key || 'unknown'}Rte`"
                v-model="controls.popupItem.description"
                :profileId="profileId"
                @change="
                  (e) => {
                    popupsArrayHandler.triggerOnChange(
                      'update',
                      controls.popupItem.key + '.description'
                    );
                    debounceAutoSave(e);
                  }
                "
              />
            </div>
            <v-text-area
              v-else
              :placeholder="
                popupsArrayHandler.getDefaultFromMasterByKey(
                  controls.popupItem.key,
                  'description'
                )
              "
              v-model="controls.popupItem.description"
              @change="
                (e) => {
                  popupsArrayHandler.triggerOnChange(
                    'update',
                    controls.popupItem.key + '.description'
                  );
                  debounceAutoSave(e);
                }
              "
            />
          </div>
        </template>
      </v-modal>
      <v-modal :modalName="'modal-publish-warning'" title="Profile Checklist">
        <template #content>
          <div class="bg-white flex flex-col">
            <div class="text-2xl">
              <div class="px-12 pt-6 pb-12">
                <div
                  v-for="item in requiredForPublish(profile)"
                  :key="item.title"
                  class="cursor-pointer text-black border-b"
                  @click="
                    () => {
                      scrollToRef(item.target, item.section);
                    }
                  "
                >
                  <div class="py-6 flex justify-between items-center">
                    <span class="mr-2">
                      <span
                        ><i class="fa-solid fa-xmark mr-4 text-black"></i
                        >{{ item.title }}</span
                      >
                    </span>
                    <div class="w-40" />
                    <i
                      class="fa-regular fa-chevron-right text-2xl text-black"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-modal>

      <v-modal
        @close="savingProfile.subscribing = false"
        :modalName="'modal-publish-consent'"
        title="Disclaimer"
      >
        <template #content>
          <string-to-html :html="profile.consentText" />
          <div class="w-96 m-12">
            <pm-button @click="publish(false)"> I Agree </pm-button>
          </div>
        </template>
      </v-modal>
      <!-- Permissions disclaimer -->
      <v-modal
        modalName="modal-permissions-disclaimer"
        title="Edit Template Permissions"
        classes="modal-content-compressed"
      >
        <template #content>
          <div class="p-16">
            <p class="text-3xl text-gray-400"
              >By editing this template, all profile users linked to the
              template will be updated once changes have been made and applied.
              The following options are available to set permissions:</p
            >
            <div>
              <div class="mb-6 mt-16"
                ><i
                  class="fa-solid fa-asterisk text-4xl mr-6 text-success w-12"
                ></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section is mandatory</span
                >
              </div>
              <div class="mb-6"
                ><i
                  class="fa-solid fa-asterisk text-4xl mr-6 text-gray-400 w-12"
                ></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section is not mandatory</span
                >
              </div>
              <div class="mb-6"
                ><i class="fa-solid fa-eye text-3xl mr-6 text-success w-12"></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section is visible to profile users</span
                >
              </div>
              <div class="mb-6"
                ><i
                  class="fa-sharp fa-solid fa-eye-slash text-3xl mr-6 text-gray-400 w-12"
                ></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section is not visible to profile users</span
                >
              </div>
              <div class="mb-6"
                ><i
                  class="fa-solid fa-lock-open text-3xl mr-6 text-success w-12"
                ></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section can be edited by profile users</span
                >
              </div>
              <div
                ><i
                  class="fa-solid fa-lock text-3xl mr-6 text-gray-400 w-12"
                ></i>
                <span class="text-3xl text-gray-400"
                  >Field / Section can not be edited by profile users</span
                >
              </div>
            </div>
          </div>
          <div class="w-96 mb-12 ml-12">
            <pm-button @click="closeModal('modal-permissions-disclaimer')">
              I Understand
            </pm-button>
          </div>
        </template>
      </v-modal>
      <v-modal
        :modalName="'modal-links'"
        title="Add a button"
        :prevent-close="true"
      >
        <template #content>
          <div class="p-16">
            <h1 class="mx-2 mb-10" :class="classes.blueHeader"
              >Add one of the following buttons:</h1
            >
            <div
              class="grid gap-10 md:grid-cols-3 md:grid-rows-2 addSectionModal"
            >
              <button
                class="link"
                :class="[classes.sectionAddButton, , 'border-brand-color']"
                @click="addAnotherLink('url')"
              >
                Link button
                <div class="text-brand-color">
                  <i class="anchor fa fa-link mr-4"></i>
                </div>
              </button>
              <button
                class="popup"
                :class="[classes.sectionAddButton, 'border-brand-color']"
                @click="addAnotherLink('modal')"
              >
                Pop-up button
                <div class="text-brand-color">
                  <i class="fa-solid fa-arrow-up-right-from-square mr-4"></i>
                </div>
              </button>
              <button
                v-if="profile.isMaster"
                class="popup"
                :disabled="
                  findItem(linksArrayHandler.childArray, {
                    contactType: 'placeholder'
                  })
                "
                :class="[
                  classes.sectionAddButton,
                  {
                    'border-brand-color': !findItem(
                      linksArrayHandler.childArray,
                      { contactType: 'placeholder' }
                    )
                  }
                ]"
                @click="addAnotherLink('placeholder')"
              >
                Placeholder
                <div class="text-brand-color">
                  <i class="fa-solid fa-ghost"></i>
                </div>
              </button>
            </div>
          </div>
        </template>
      </v-modal>
      <v-modal
        :modalName="'modal-section'"
        title="Add a section"
        :prevent-close="true"
      >
        <template #content>
          <div class="p-16">
            <h1 class="mx-2 mb-10" :class="classes.blueHeader"
              >Add one of the following sections:</h1
            >
            <div
              class="grid gap-10 md:grid-cols-3 md:grid-rows-2 addSectionModal"
            >
              <button
                :disabled="
                  findItem(sectionHandler.state.infoItems, { type: 'aboutme' })
                "
                class="aboutme"
                :class="[
                  classes.sectionAddButton,
                  {
                    'border-brand-color': !findItem(
                      sectionHandler.state.infoItems,
                      { type: 'aboutme' }
                    )
                  }
                ]"
                @click="addSection('aboutme', 'About me')"
              >
                About me
                <div
                  :class="[
                    'text-gray-500',
                    {
                      'text-brand-color': !findItem(
                        sectionHandler.state.infoItems,
                        { type: 'aboutme' }
                      )
                    }
                  ]"
                >
                  <i class="fa-brands fa-youtube mr-4"></i>
                  <i class="fa-solid fa-text"></i>
                </div>
              </button>
              <button
                class="video"
                :class="[classes.sectionAddButton, 'border-brand-color']"
                @click="addSection('videoGallery', 'Video gallery')"
              >
                Video gallery
                <div class="text-brand-color">
                  <i class="fa-brands fa-youtube mr-4"></i>
                  <i class="fa-brands fa-youtube mr-4"></i>
                  <i class="fa-brands fa-youtube"></i>
                </div>
              </button>
              <button
                :disabled="
                  findItem(sectionHandler.state.infoItems, {
                    type: 'testimonials'
                  })
                "
                class="testimonial"
                :class="[
                  classes.sectionAddButton,
                  {
                    'border-brand-color': !findItem(
                      sectionHandler.state.infoItems,
                      { type: 'testimonials' }
                    )
                  }
                ]"
                @click="addSection('testimonials', 'Testimonials')"
              >
                Testimonial
                <div
                  :class="[
                    'text-gray-500',
                    {
                      'text-brand-color': !findItem(
                        sectionHandler.state.infoItems,
                        { type: 'testimonials' }
                      )
                    }
                  ]"
                >
                  <i class="fa-solid fa-text mr-4"></i>
                  <i class="fa-regular fa-star-half-stroke"></i>
                </div>
              </button>
              <button
                class="image"
                :class="[classes.sectionAddButton, 'border-brand-color']"
                @click="addSection('imageGallery', 'Image gallery')"
              >
                Image gallery
                <div class="text-brand-color">
                  <i class="fa-solid fa-image-landscape mr-4"></i>
                  <i class="fa-solid fa-image-landscape mr-4"></i>
                  <i class="fa-solid fa-image-landscape"></i>
                </div>
              </button>
              <button
                class="rte"
                :class="[classes.sectionAddButton, 'border-brand-color']"
                @click="addSection('text', 'Text')"
              >
                Multimedia
                <div class="text-brand-color font-bold">
                  <i class="fa-solid fa-text"></i>
                  <i class="fa-brands fa-youtube mx-4"></i>
                  <i class="fa-solid fa-image-landscape"></i>
                </div>
              </button>
            </div>
          </div>
        </template>
      </v-modal>
      <!-- Alias creation -->
      <v-modal
        :modalName="'modal-alias'"
        title="My Profile Link"
        :prevent-close="true"
      >
        <template #content>
          <div class="bg-white flex flex-col">
            <div class="text-2xl">
              <div class="p-6">
                <v-input-wrapper class="my-6 border-b flex items-center">
                  <input
                    :value="profileAlias.test"
                    @keydown="isLetter"
                    @input="checkProfileAlias"
                    type="text"
                    style="text-transform: lowercase"
                    :class="classes.inputs"
                    name="profile-alias"
                    id="profilealias"
                    placeholder=" "
                  />
                  <label
                    for="firstname"
                    :class="`floating-label ${classes.placeholder}`"
                    >Your preferred website link</label
                  >
                  <i
                    @click="focusElement('profilealias')"
                    class="fa-solid fa-pencil edit-icon"
                  ></i>
                </v-input-wrapper>
                <p class="text-2xl text-gray-400 my-6">{{
                  companyDomainAliasExample
                }}</p>
                <p class="text-2xl font-bold text-brand-color mb-6"
                  >Please Note: This name can not be changed / edited
                </p>

                <pm-button
                  :disabled="!profileAlias.approved || profileAlias.testing"
                  @click="publish(false)"
                >
                  {{ profileAlias.testing ? 'Please wait...' : 'Continue' }}
                </pm-button>
              </div>
            </div>
          </div>
        </template>
      </v-modal>
      <!-- Welcome Message -->
      <vue-final-modal
        :fit-parent="false"
        content-class="modal-content"
        v-model="showWelcomeMessage"
        :name="'modal-welcome'"
        classes="modal-container"
        :click-to-close="false"
        :esc-to-close="false"
      >
        <div class="flex flex-col bg-white">
          <div class="p-12">
            <p class="text-4xl mb-2">Welcome to Self Service </p>
            <p class="text-2xl text-gray-400 pb-6">See what you can do here:</p>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/evUWrhH01Rc?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <div class="w-96 self-center mb-6">
            <pm-button @click="closeModal('modal-welcome')">Continue</pm-button>
          </div>
        </div>
      </vue-final-modal>
      <!-- Building Profile -->
      <vue-final-modal
        :fit-parent="false"
        v-model="savingProfile.publishing"
        :name="'modal-publish-loading'"
        classes="modal-container"
        content-class="modal-content"
        :click-to-close="false"
        :esc-to-close="false"
      >
        <div class="flex flex-col bg-white">
          <div class="p-6 flex justify-center items-center bg-brand-color">
            <img alt="dark-logo-image" class="h-32" :src="lightLogo" />
          </div>
          <div class="p-12 pb-20 flex justify-center items-center flex-col">
            <p v-if="!firstBuild" class="my-16 px-6 text-4xl text-center">
              Please wait while your changes are being published
            </p>
            <p class="my-16 px-6 text-3xl text-center" v-else
              >Please wait 30 seconds while your website is being published to
              the internet for the first time!</p
            >

            <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                class="progress-bg h-2.5 rounded-full"
                :style="`width: ${publishProgress}%`"
              ></div>
            </div>
          </div>
        </div>
      </vue-final-modal>

      <v-modal
        :modalName="'modal-photo-gallery'"
        @closed="resetImageGalleryItem"
        title="Photo Gallery Item"
      >
        <template #content>
          <image-gallery-item
            v-permissions="permissions"
            :identifier="imageGalleryItem.type"
            :image="imageGalleryItem"
            :classes="classes"
            :loading="loadingImageGalleryItem"
            @handle="addImageByType"
            @change="
              ({ type }) =>
                sectionHandler
                  .getItemsArrayWrapper(imageGalleryItem.type)
                  .triggerOnChange(
                    'update',
                    imageGalleryItem.type +
                      '.list.' +
                      imageGalleryItem.key +
                      '.' +
                      type
                  )
            "
          />
        </template>
      </v-modal>
    </div>
    <div class="fixed btn-position z-10">
      <slot name="controlbar"></slot>
    </div>
    <div class="h-64" />
  </main>
</template>

<script>
import accordion from '~/components/ui/v-accordion.vue';
import VInputWrapper from '~/components/ui/v-input-wrapper.vue';
import verticalDots from '~/components/icons/vertical-dots.vue';
import IconSelector from '~/components/ui/v-icon-selector.vue';
import Draggable from 'vuedraggable';
import EditText from '~/components/functional/edit-text.vue';
import vInput from '~/components/ui/v-input-pencil.vue';
import VModal from '~/components/functional/v-modal.vue';
import PhotoEditor from '~/components/functional/photo-editor.vue';
import SocialMediaSelector from '~/components/functional/v-social-media-selector.vue';
import { VueFinalModal } from 'vue-final-modal';
import pmButton from '~/components/ui/pm-button.vue';
import loadingImage from '~/components/ui/loading-image.vue';
import eyeSwitch from '~/components/ui/eye-switch.vue';
import arrayMergeItem from '~/components/functional/array-merge-item.vue';
import arrayMergeContacts from '~/components/functional/array-merge-contacts.vue';
import colorPicker from '~/components/functional/color-picker.vue';
import cropper from '~/components/functional/cropper.vue';
import vTextArea from '~/components/ui/v-textarea.vue';
import addressInput from '~/components/functional/address-input.vue';
import stringToHtml from '~/components/ui/string-to-html.vue';
import ImageGalleryItem from './image-gallery-item.vue';
import PermissionControls from '~/components/functional/permission-controls.vue';
import { toRef } from '@vue/reactivity';
import permissionsDirective from '~/functions/directives/permissions-directive';
import UploadEditCrop from '~/components/functional/upload-edit-crop.vue';
import VSwitch from '~/components/ui/v-switch.vue';
import confirmDialog from '~/components/ui/confirm-dialog.vue';
import themesForm from '~/components/functional/themes-form.vue';
import { find, replace } from 'lodash';
import { convertURLToImagePreview } from '~/api/youtube/utils/convert-url.util';

import VQuill from '~/components/functional/v-quill.vue';
import vCssEditor from '~/components/functional/v-css-editor.vue';
import GenerateQrCode from '~/components/functional/qr-generator/generate-qr-code.vue';
import vTestimonials from '~/components/functional/v-testimonials.vue';

export default {
  inject: ['toast'],
  directives: {
    permissions: permissionsDirective
  },
  components: {
    vCssEditor,
    GenerateQrCode,
    VQuill,
    themesForm,
    VSwitch,
    arrayMergeContacts,
    confirmDialog,
    UploadEditCrop,
    vInput,
    accordion,
    stringToHtml,
    cropper,
    addressInput,
    vTextArea,
    loadingImage,
    VInputWrapper,
    colorPicker,
    verticalDots,
    pmButton,
    eyeSwitch,
    VueFinalModal,
    EditText,
    Draggable,
    VModal,
    SocialMediaSelector,
    IconSelector,
    PhotoEditor,
    vTestimonials,
    arrayMergeItem,
    ImageGalleryItem,
    PermissionControls
  },
  props: {
    controls: {
      type: Object,
      default: () => {}
    },
    sectionHandler: {
      type: Object,
      default: () => {}
    },
    linksArrayHandler: {
      type: Object,
      default: () => {}
    },
    socialMediaArrayHandler: {
      type: Object,
      default: () => {}
    },
    popupsArrayHandler: {
      type: Object,
      default: () => {}
    },
    featureFlagHandler: {
      type: Object,
      default: () => {}
    },
    variableRecordHandler: {
      type: Object,
      default: () => {}
    },
    environment: {
      type: String,
      default: 'production'
    },
    mode: {
      type: String,
      default: 'default'
    }
  },
  setup(props) {
    const controlFunctions = toRef(props, 'controls');
    const MODE = toRef(props, 'mode');
    const classes = {
      inputs:
        'p-6 text-black text-4xl flex-1 md:text-3xl placeholder:text-black active:border-none active:outline-none',
      inputsCompressed:
        'mb-0 flex-1 text-black bg-transparent text-4xl flex-1 placeholder:text-black active:border-none active:outline-none',
      placeholder:
        'p-6 text-gray-600 mr-32 text-4xl mb-6 md:text-3xl placeholder:text-black active:border-none active:outline-none',
      header: 'font-bold text-4xl md:text-3xl text-black py-2',
      subHeader: 'text-4xl md:text-3xl text-black',
      blueHeader: 'font-blue bg-transparent text-3xl cursor-pointer',
      colorPlaceholder: 'text-4xl md:text-3xl text-black',
      buttonSize:
        'md:w-max fill-available px-24 py-10 md:px-16 md:py-6 mt-6 text-4xl',
      sectionAddButton:
        'text-4xl text-black flex justify-between items-center px-10 border-2 md:text-3xl'
    };
    const cropper_config = {
      logo: {
        aspect: NaN,
        maxWidth: 801,
        maxHeight: 120,
        minWidth: 267,
        minHeight: 40
      },
      banner: {
        aspect: NaN,
        maxWidth: 1900,
        maxHeight: 800,
        minWidth: 630,
        minHeight: 260
      },
      mobileBanner: {
        aspect: 800 / 507,
        maxWidth: 800,
        maxHeight: 507,
        minWidth: 260,
        minHeight: 169
      },
      desktopBanner: {
        aspect: NaN,
        maxWidth: 1280,
        maxHeight: 507,
        minWidth: 426,
        minHeight: 169
      },
      profilePhoto: {
        aspect: 1,
        maxWidth: 1280,
        maxHeight: 1280,
        minWidth: 256,
        minHeight: 256
      },
      default: {
        aspect: NaN,
        maxWidth: 1280,
        maxHeight: 1280,
        minWidth: 256,
        minHeight: 256
      }
    };
    return {
      getMasterProfileDefault: (key) => {
        if (
          controlFunctions.value.profile.masterProfile?.cssVariables &&
          controlFunctions.value.profile.masterProfile?.cssVariables[key]
        ) {
          return controlFunctions.value.profile.masterProfile?.cssVariables[
            key
          ];
        }
      },
      profileId: props.controls.profileId.value,
      findItem: (arr, exp) => find(arr, exp),
      MODE: MODE.value,
      classes,
      ...controlFunctions.value,
      stopProp(e) {
        e.stopPropagation();
      },
      fetchCropperConfig(key, whichValue) {
        if (key && cropper_config[key]) {
          return cropper_config[key][whichValue];
        }
        return cropper_config['default'][whichValue];
      }
    };
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text);
      this.toast({ success: true, message: 'Copied!' });
    },
    formatImageThumbnail(url) {
      return replace(url, 'upload/', 'upload/w_400/');
    },
    convertURLToImagePreview
  },
  computed: {
    previewLink() {
      const domain = new URL(window.document.URL);
      return `${domain.origin}/preview-link.html?stylingId=${
        this.controls.profile.stylingId || 0
      }&profileId=${this.controls.profile.profileId}${
        this.environment == 'development' ? '&devCDN=true' : ''
      }${this.environment == 'development' ? '&devDB=true' : ''}`;
    },
    hasPermissions() {
      return this.featureFlagHandler.allowedFeaturePerProfile(
        this.controls.getCompany.value
      )['permissions']
        ? 'permissions'
        : 'default';
    },
    hasRte() {
      return this.featureFlagHandler.allowedFeaturePerProfile(
        this.controls.getCompany.value
      )['rte'];
    },
    hasStyling() {
      return (
        this.featureFlagHandler.allowedFeaturePerProfile(
          this.controls.getCompany.value
        )['style_editing'] || this.featureFlagHandler.isSuperAdmin
      );
    }
  }
};
</script>

<style lang="css" scoped>
@import url('../../self-service.css');

.cursor-disabled {
  cursor: not-allowed;
}

.phone-border {
  border-color: black;
  border-style: solid;
  border-width: 1.5rem 0.25rem;
  border-image: none 100% / 1 / 0 stretch;
  border-radius: 1.5rem;
}

.p :v-deep(.embedded_html) .ul {
  list-style-type: circle;
}

.contain {
  object-fit: contain !important;
}

.w-qrcode {
  max-width: 300px;
}

.w-90 {
  width: 90%;
}

.addSectionModal {
  grid-template-rows: 60px;
  grid-auto-rows: 60px;
}

@media screen and (min-width: 768px) {
  .addSectionModal {
    grid-template-rows: 70px;
    grid-auto-rows: 70px;
  }
}

.rteModalSection {
  height: 45vh;
}

.rteModalEditContainer {
  height: 20vh;
}

@media screen and (min-width: 768px) {
  .rteModalSection {
    height: 80vh;
  }

  .rteModalEditContainer {
    height: 60vh;
  }
}
</style>
