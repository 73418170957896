<template>
  <div class="relative">
    <div
      id="scroll-to-top"
      @click="scrollToTop"
      class="rounded-full w-16 z-10 flex justify-center items-center h-16 bg-gray cursor-pointer hidden bottom-6 right-6"
    >
      <i class="fas fa-chevron-up text-white" />
    </div>
    <div class="min-h-screen flex flex-col bg-gray-100">
      <v-navbar
        class="sticky top-0 z-20 md:static"
        id="nav-bar"
        @clickOpen="navAccordian = !navAccordian"
      />
      <div class="flex flex-1 flex-col md:flex-row h-full w-full">
        <v-sidenav
          id="sidenav"
          :approvals="approvals"
          class="z-10 hidden md:flex md:z-0"
          :links="allowedLinks"
          :value="getReport"
          @link-select="toggleLoader(true)"
        ></v-sidenav>
        <accordion
          padding="p-0"
          class="sticky top-32 z-20 md:hidden w-full"
          titleBackground="bg-brand-color text-white"
          :open="navAccordian"
        >
          <v-sidenav
            id="sidenav"
            class="border-t border-white"
            @link-select="
              () => {
                toggleLoader(true);
                navAccordian = false;
              }
            "
            :links="allowedLinks"
            :value="getReport"
          ></v-sidenav>
        </accordion>
        <div
          :class="
            getReport === 'self-service' || getReport === 'manage-templates'
              ? 'reports md:px-16 flex-1 relative'
              : 'py-12 reports md:px-16 flex-1 relative'
          "
        >
          <div :class="getToolbarMargin">
            <h1
              class="text-3-5xl text-gray-900 w-full leading-none px-5 md:px-0 flex items-center lg:w-fit"
              v-html="title"
            ></h1>
          </div>
          <div class="flex">
            <component
              class="flex-1 px-6 md:px-0"
              v-if="!loading"
              id="reports"
              :profilesHandler="profilesHandler"
              :suggestedProfiles="suggestedProfiles"
              :suggestedCompanies="suggestedCompanies"
              :featureFlagHandler="featureFlagHandler"
              :profileQueryHandler="profileQueryHandler"
              :profilesLoading="profilesState.loadingState.profiles"
              :fetchExcelData="fetchExcelData"
              :padRows="userRole(user) == 'user' ? 5 : 10"
              :reports="dataRefs"
              :titleValues="titleValues"
              :is="getReport"
              :defaultThemes="defaultThemes"
              :profiles="{
                published: publishedProfiles,
                unpublished: unpublishedProfiles,
                all: allProfiles
              }"
              :allApprovals="approvals"
              :useActiveApprovals="useActiveApprovals"
              :selfServiceProfile="selfServiceProfile"
              :selfServiceFunctions="selfServiceFunctions"
              :userManagementFunctions="userManagementFunctions"
              :start="currentDates.start"
              :end="currentDates.end"
              :userRole="userRole(user)"
              :user="user"
              @fetchSelfServiceProfile="fetchSelfServiceProfile"
              @toggleApprovalStatus="toggleApprovalStatus"
              @fetchApprovals="reloadApprovals"
              @reloadProfiles="reloadProfiles"
              @fetchUserSuggestions="fetchUserSuggestions"
              @fetchCompanySuggestions="fetchCompanySuggestions"
              @openPreview="openPreview"
            >
              <template #datepicker>
                <div
                  id="date-picker"
                  style="top: 68px"
                  class="sticky md:static z-10 mb-10"
                >
                  <v-date-picker
                    :defaultDate="currentDates"
                    :defaultTitle="dateDropdownTitle"
                    @change-date="onDateChange"
                    @change-title="(title) => (dateTitle = title)"
                    @change-dropdown-title="
                      (title) => (dateDropdownTitle = title)
                    "
                  >
                  </v-date-picker>
                </div>
              </template>
            </component>

            <preview
              v-show="
                getReport === 'self-service' || getReport === 'manage-templates'
              "
              class="top-5 phone-border phone-border-container mt-5 sticky hidden lg:block"
              :isModal="false"
              @loaded="iframesLoaded.mobile = true"
            ></preview>
          </div>
        </div>
      </div>
    </div>
    <vue-final-modal
      v-model="showingPreview"
      class="top-0"
      :fit-parent="false"
      :esc-to-close="false"
      :name="'modal-preview'"
      @open="openPreview"
      @close="closePreview"
    >
      <preview
        class="w-full h-screen"
        @closePreview="closePreview"
        @loaded="iframesLoaded.desktop = true"
      >
        <div id="previewPopup"> </div>
      </preview>
    </vue-final-modal>
  </div>
</template>
<script>
//pages
import statistics from '~/views/reports/statistics';
import users from '~/views/reports/users';
import selfService from '~/views/self-service/self-service-page.vue';
import approvals from '~/views/self-service/approvals-page.vue';
import templates from '~/views/self-service/templates-page.vue';
import manageTemplates from '~/views/self-service/manage-templates-page.vue';
import home from '~/views/home';

// components
import accordion from '~/components/ui/v-accordion.vue';
import vNavbar from '~/components/functional/v-navbar.vue';
import vSidenav from '~/components/functional/v-sidenav.vue';
import vDatePicker from '~/components/functional/v-date-picker.vue';
import vLoader from '~/components/ui/v-loader.vue';

// constants
import { links } from '~/constants/titles';
import { userRole } from '~/functions/utils-functions';

// utils
import { onBeforeRouteUpdate } from 'vue-router';
import { formatDateForTitle } from '~/functions/date-functions';
import { smartJoin, setMockImage } from '~/functions/utils-functions';
import { filter, chain, isEmpty } from 'lodash';
import { computed, ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import userStateManager from '~/store/state-managers/user';
// wrappers
import featureFlagWrapper from '~/functions/wrappers/feature-flag-wrapper';
import useTitles from '~/functions/setup/useTitles';
import useCompany from '~/functions/setup/useCompany';
import useProfiles from '~/functions/setup/useProfiles';
import useApprovals from '~/functions/setup/useApprovals';
import useDataRefs from '~/functions/setup/useDataRefs';
import useSelfService from '~/functions/setup/useSelfService';
import useThemes from '~/functions/setup/useThemes';
import { useRoute } from 'vue-router';
import selfServiceApi from '~/api/self-service-v4';
import manageUsersApi from '~/api/manageUsers';
import { selfServiceSchema } from '~/constants/schemas';
import preview from '~/components/functional/preview.vue';
import { VueFinalModal } from 'vue-final-modal';

export default {
  emits: ['setLoader'],
  setup(_, { emit }) {
    const route = useRoute();
    // Variables
    const store = useStore();
    const loading = ref(false);
    const iframesLoaded = reactive({
      mobile: false,
      desktop: false
    });
    const showingPreview = ref(false);
    const dateDropdownTitle = ref('');
    const navAccordian = ref(false);
    const titleValues = reactive({});

    const report = (_route) => (_route || route).params.report;
    const alias = (_route) => (_route || route).params.alias;

    const apiCallsStatus = reactive({
      dataRefs: true,
      selfServiceProfile: true,
      approvals: true
    });

    const toggleLoader = (value = false) => {
      if (value) {
        loading.value = value;
        emit('setLoader', loading.value);
      } else {
        // We need to wait for the DOM to update before we can set the loader to false
        // nextTick doesn't work. setTimeout does.
        setTimeout(() => {
          loading.value = value;
          emit('setLoader', loading.value);
        }, 10);
      }
    };

    const isApisFinished = () => {
      for (const i in apiCallsStatus) {
        if (!apiCallsStatus[i]) {
          return false;
        }
      }

      return true && iframesLoaded.mobile && iframesLoaded.desktop;
    };

    const {
      user,
      supervisorCompanies,
      getUser,
      getProfileId,
      isUser,
      isSuperAdmin,
      getCurrentFilteredDivision,
      getCurrentFilteredProfile
    } = userStateManager();
    const selfServiceFunctions = selfServiceApi(
      sessionStorage.getItem('_token')
    );
    const userManagementFunctions = manageUsersApi(
      sessionStorage.getItem('_token')
    );
    const featureFlagHandler = featureFlagWrapper(
      store.state.auth.user.companies,
      userRole(getUser())
    );

    const { setTitle, getTitle } = useTitles(reactive);
    setTitle({
      companies: getUser().companies,
      isUser,
      username: getUser().username,
      report: report(),
      urlAlias: alias(),
      division: getCurrentFilteredDivision(),
      profile: getCurrentFilteredProfile()
    });
    const title = computed(() => getTitle(report()));

    const allowedLinks = computed(() => {
      // Hide premium hardcode`
      if (featureFlagHandler.isSupervisor) {
        return filter(
          links,
          (l) =>
            l.label == 'Home' ||
            (l.label == 'Approvals' &&
              featureFlagHandler.allowedFeature['approvals']) ||
            (l.label == 'Templates' &&
              featureFlagHandler.allowedFeature['templates']) ||
            l.label == 'Statistics' ||
            l.label == 'Users'
        );
      } else if (isSuperAdmin) {
        return filter(links, (l) => l.label != 'Profile');
      } else if (isUser) {
        // add filter for profile type 'premium'
        return filter(
          links,
          (l) =>
            l.label == 'Home' || l.label == 'Statistics' || l.label == 'Profile'
        );
      }
      return links;
    });

    const getToolbarMargin = computed(() => {
      return `flex items-center justify-between ${
        route.path !== '/self-service' && route.path !== '/manage-templates'
          ? 'mb-12'
          : ''
      }`;
    });
    //#region Statistics
    const { suggestedCompanies, fetchCompanySuggestions, resetCompanySearch } =
      useCompany(supervisorCompanies.value);
    const {
      dataRefs,
      currentDates,
      statsState,
      onDateChange,
      fetchData,
      updateQuery,
      statsProfileId
    } = useDataRefs(
      () => {
        apiCallsStatus.dataRefs = false;
        toggleLoader(true);
      },
      () => {
        apiCallsStatus.dataRefs = true;
        if (isApisFinished()) {
          toggleLoader();
          setTitle({ isProfileStats: statsProfileId });
        }
        setTitle({ isProfileStats: statsProfileId });
      },
      reactive
    );

    const dateTitle = ref(
      smartJoin(
        [
          formatDateForTitle(currentDates.start),
          formatDateForTitle(currentDates.end)
        ],
        ' - '
      )
    );
    //#endregion

    //#region Profiles
    const {
      publishedProfiles,
      unpublishedProfiles,
      allProfiles,
      profilesHandler,
      profilesState,
      profileQueryHandler,
      suggestedProfiles,
      reloadProfiles,
      fetchUserSuggestions,
      fetchExcelData,
      resetUserSearch
    } = useProfiles(
      supervisorCompanies.value,
      () => {
        setTitle({
          profileCount: allProfiles?.value.total || 0,
          templatesCount: profilesHandler.size
        });
      },
      reactive
    );
    //#endregion

    //#region Self service
    const { fetchSelfServiceProfile, selfServiceProfile } = useSelfService(
      (query) => {
        if (!query.noLoading) {
          apiCallsStatus.selfServiceProfile = false;
          toggleLoader(true);
        }
      },
      () => {
        apiCallsStatus.selfServiceProfile = true;
        if (isApisFinished()) {
          toggleLoader();
        }
      }
    );
    //#endregion

    //#region Approvals
    const {
      useActiveApprovals,
      approvals,
      toggleApprovalStatus,
      reloadApprovals
    } = useApprovals(
      supervisorCompanies.value,
      () => {
        apiCallsStatus.approvals = false;
        toggleLoader(true);
      },
      () => {
        apiCallsStatus.approvals = true;
        setTitle({
          approvalsCount: chain(approvals).get('value').size().value()
        });
        if (isApisFinished()) {
          toggleLoader(false);
        }
      }
    );
    //#endregion

    const { defaultThemes } = useThemes();
    /**
     * What happens on navigate
     * - Update title
     * - Reset the stats query
     * - Fetch the self service profile
     * - Reload the users profile
     * - Toggle the loader on/off
     */
    const profileIdInAlias = (_alias) =>
      chain(_alias).defaultTo('_').split('_').first().value();
    const companyIdInAlias = (_alias) =>
      chain(_alias).defaultTo('_').split('_').get('1').value() &&
      !profileIdInAlias(_alias);

    onBeforeRouteUpdate((to, from) => {
      if (report(from) == 'users') {
        if (
          !isEmpty(store.state.supervisor.filterByDivision) ||
          profileQueryHandler.query.filter
        ) {
          profileQueryHandler.resetQuery();
          reloadProfiles();
        }
      }

      if (report(from) != report(to)) {
        store.commit('supervisor/setDivisionFilter');
      }
      // When you leave statistics page
      if (report(from) == 'statistics' && report(to) != 'statistics') {
        // Remove global state
        store.commit('supervisor/setProfileFilter');
        resetUserSearch();
        // Remove filter if leaving statistics page
        if (alias(from)) {
          updateQuery(alias(to));
          fetchData();
          setTitle({
            division: getCurrentFilteredDivision(),
            profile: getCurrentFilteredProfile()
          });
        }
      }

      // When you go from one alias to another in statistics page, clear the state
      if (report(to) == 'statistics' && alias(from)) {
        if (profileIdInAlias(alias(from)) && !profileIdInAlias(alias(to)))
          store.commit('supervisor/setProfileFilter');
        if (companyIdInAlias(alias(from)) && !companyIdInAlias(alias(to)))
          store.commit('supervisor/setDivisionFilter');
      }

      // Loading statistics for the first time, or changing statistics filtering (see previous if statement)
      if (
        report(to) == 'statistics' &&
        (statsState.firstLoad || from.params.alias != to.params.alias)
      ) {
        updateQuery(alias(to));
        fetchData();
        statsState.firstLoad = false;
        setTitle({
          division: getCurrentFilteredDivision(),
          profile: getCurrentFilteredProfile()
        });
      }

      if (report(to) == 'self-service' || report(to) == 'manage-templates') {
        fetchSelfServiceProfile({
          profileId: isUser ? user.value.profile : profileIdInAlias(alias(to))
        });
      }

      if (isApisFinished()) toggleLoader(false);
      resetCompanySearch();
      setTitle({ report: report(to), urlAlias: alias(to) });
    });
    /**
     * What happens on initial load:
     * - Load users/templates if not user
     * - Load company search
     * - Load Self service if in self service
     * - Load stats if on the stats page
     * - Load approvals if a supervisor/admin
     * - Load uses search if a supervisor/admin
     */
    localStorage.removeItem('_c');
    resetUserSearch();
    if (report() == 'statistics') {
      statsState.firstLoad = false;
      updateQuery(alias());
      fetchData();
    }
    if (report() == 'self-service' || report() == 'manage-templates') {
      fetchSelfServiceProfile({
        profileId: getProfileId()
      });
    }
    if (featureFlagHandler.isSupervisor || featureFlagHandler.isSuperAdmin) {
      reloadApprovals();
      reloadProfiles();
    }
    resetCompanySearch();

    watch(iframesLoaded, () => {
      if (iframesLoaded.mobile && iframesLoaded.desktop) {
        toggleLoader(false);
      }
    });

    return {
      selfServiceSchema,
      // profiles
      publishedProfiles,
      unpublishedProfiles,
      allProfiles,
      titleValues,
      profilesState,
      reloadProfiles,
      suggestedProfiles,
      fetchUserSuggestions,
      fetchExcelData,
      // Companies
      suggestedCompanies,
      fetchCompanySuggestions,
      //handlers
      profilesHandler,
      featureFlagHandler,
      profileQueryHandler,
      //other
      showingPreview,
      openPreview: () => (showingPreview.value = true),
      closePreview: () => {
        if (report() != 'self-service' && report() != 'manage-templates') {
          store.commit('selfServicePreview/showLoader');
        }
      },
      toggleLoader,
      dateDropdownTitle,
      setMockImage,
      getToolbarMargin,
      reloadApprovals,
      approvals,
      useActiveApprovals,
      toggleApprovalStatus,
      links,
      title,
      dateTitle,
      loading,
      iframesLoaded,
      getReport: computed(() => report()),
      selfServiceProfile,
      navAccordian,
      fetchSelfServiceProfile,
      allowedLinks,
      // Todo: this needs to be replaced by global state
      userRole,
      user,
      defaultThemes,
      selfServiceFunctions,
      userManagementFunctions,
      selfService,
      onDateChange: (date) => {
        onDateChange(date);
        updateQuery(alias());
        fetchData();
        setTitle({ dateTitle });
      },
      currentDates,
      dataRefs
    };
  },
  components: {
    accordion,
    vNavbar,
    vSidenav,
    statistics,
    selfService,
    templates,
    approvals,
    users,
    manageTemplates,
    vDatePicker,
    vLoader,
    home,
    preview,
    VueFinalModal
  },
  mounted() {
    window.addEventListener('scroll', function () {
      const scrollTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
      if (scrollTop > 500) {
        this.document
          .getElementById('scroll-to-top')
          .classList.remove('hidden');

        this.document.getElementById('scroll-to-top')?.classList.add('fixed');
      } else {
        this.document.getElementById('scroll-to-top')?.classList.add('hidden');

        this.document
          .getElementById('scroll-to-top')
          ?.classList.remove('fixed');
      }
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  data() {
    return {
      path: 'home'
    };
  }
};
</script>
