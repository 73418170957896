<script setup>
import ProfileMeLib from '@profilemeapp/profile-me-library/dist/App/ProfileMeApp.mjs';
import { computed, ref, watch, onBeforeMount } from 'vue';
import vLoader from '~/components/ui/v-loader.vue';
import store from '~/store/index';
const { ProfileMeApp } = ProfileMeLib;
const loader = ref(true);

const content = computed(() => {
  return store.state.selfServicePreview.profile;
});

const stateLoader = computed(() => {
  return store.state.selfServicePreview.loader;
});

watch(stateLoader, (value) => {
  if (value) {
    loader.value = value;
  } else
    setTimeout(() => {
      loader.value = value;
    }, 700);
});
onBeforeMount(async () => {
  await import('@profilemeapp/profile-me-library/dist/App/style.css');
});
</script>
<template>
  <div class="profile-me-app-preview relative">
    <ProfileMeApp
      :details="content"
      :enableAnalytics="false"
      :productionCollections="false"
      :leadFormData="[]"
      :previewMode="false"
    ></ProfileMeApp>
    <div
      :style="{
        backgroundColor: 'white',
        position: 'fixed',
        zIndex: 1000,
        width: '100vw',
        height: '100vh',
        top: '0px',
        left: '0px',
        textAlign: 'center'
      }"
      v-if="loader"
    >
      <v-loader :hideText="true"></v-loader>
    </div>
  </div>
</template>
<style>
.profile-me-app-preview div.loadingscreen-loader {
  margin-left: auto;
  margin-right: auto;
  width: 16rem;
  height: 16rem;
}

@media (min-width: 768px) {
  .profile-me-app-preview div.loadingscreen-loader {
    width: 17rem;
    height: 17rem;
  }
}
</style>
