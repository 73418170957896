import {
  filter,
  get,
  isNil,
  chain,
  omit,
  isEmpty,
  includes,
  isString,
  find,
  map,
  every,
  isNull,
  omitBy
} from 'lodash';
import {
  joinSansUndefined as join,
  userRole
} from '~/functions/utils-functions';
import { selfServiceSchema } from '~/constants/schemas';
import { convertEmbedToURL, convertURLToEmbed } from '~/api/youtube';
import ProfileMeLibrary from '@profilemeapp/profile-me-library/dist/App/ProfileMeApp.mjs';

const filterInfoSections = ({ key, type, isModal }) =>
  !includes(['ss-lead-form'], key) &&
  isString(type) &&
  !isEmpty(type) &&
  !isModal;
const filterInfoSectionsModal = ({ key, type, isModal }) =>
  !includes(['ss-lead-form'], key) &&
  isString(type) &&
  !isEmpty(type) &&
  isModal;

/**
 * @param {*} obj An object with any type as properties
 * @returns If all the entries inside the object is null, then return null
 * otherwise return the object as it is.
 */
const collapseToNUll = (obj) => (every(obj, isNull) ? null : obj);

const uuidv4 = require('uuid');
// const ANALYTICS_ACTIONS = Object.freeze({
//   open: 'open', // type : ['modal', 'dropdown', 'share-menu']
//   scroll: 'scroll',
//   page_view: 'page_view',
//   share: 'share', // type : [ medium ]
//   'view-media': 'view-media', // type : [ 'image', 'map', 'video' ]
//   click: 'click' // type: ['sms', 'email', 'copy-link', 'accept-cookies', 'save-to-contacts', 'link']
// });

const convertDataToNewStructure = (companies, profiles, entry) => {
  const getTransform = () => {
    const getType = (type) => {
      if (type == 'copy_link') return 'copy-link';
      if (type == 'add_to_contacts') return 'phone';
      if (type == 'add_to_contacts' || type == 'phone') return 'phone';
      if (type == 'Office' || type == 'Mobile') return 'save-to-contacts';
      if (type == 'E-mail' || type == 'Email' || type == 'email')
        return 'email';
      if (type == 'whatsapp') return 'whatsapp';
      if (type == 'accept-cookies') return 'accept-cookies';
      return 'url';
    };
    // open, page_view, click, media
    if (entry['type'] == 'page_view')
      return {
        action: 'page_view',
        type: 'page_view',
        title: entry['title'],
        value: null
      };
    if (entry['action'] == 'view')
      return {
        action: 'open',
        type: 'dropdown',
        title: entry['type'],
        value: null
      };
    if (entry['type'] == 'sms')
      return {
        action: 'share',
        type: 'sms',
        title: 'SMS',
        value: null
      };
    if (entry['action'] == 'share')
      return {
        action: 'share',
        type: entry['type'].toLowerCase(),
        title: entry['type'],
        value: null
      };
    if (entry['action'] == 'click' && entry['type'] != 'sms')
      return {
        action: 'click',
        type: getType(entry.type),
        title: entry['type'],
        value: entry['value'] ? entry['value'] : 'unknown'
      };
    if (entry['action'] == 'download')
      return {
        action: 'click',
        type: 'pwa-install',
        title: null,
        value: null
      };
    if (entry['type'].includes('click-'))
      return {
        action: 'click',
        type: getType(entry['type'].split('-')[1]),
        title:
          entry['type'].split('-')[1] == 'copy_link'
            ? null
            : entry['type'].split('-')[1],
        value: null
      };
    if (entry['type'].includes('share-'))
      return {
        action: 'share',
        type: entry['type'].split('-')[1].toLowerCase(),
        title: entry['type'].split('-')[1],
        value: null
      };

    return {
      action: entry['action'],
      type: entry['type'],
      title: entry['title'],
      value: entry['value']
    };
  };

  const getUserAgent = (entry) => {
    if (entry['userAgent']) return entry['userAgent'];
    else return join([entry['device'], entry['browser']]);
  };

  return {
    subdomain: entry['subdomain'],
    companyId: companies[entry['subdomain']],
    alias: entry['alias'],
    profileId: profiles[entry['alias']],
    browserId: entry['browserId'],
    unique: entry['unique'],
    createdAt: entry['createdAt'],
    userAgent: getUserAgent(entry),
    ...getTransform()
  };
};
const fetchBannerType = (banners, type) => {
  const selfServiceBanner = {
    url: null,
    id: null,
    key: type
  };
  const _banner = chain(banners)
    .find((banner) => banner.type === type)
    .value();
  if (_banner && _banner.banner && !isEmpty(_banner.banner.slider)) {
    selfServiceBanner.url = _banner.banner.slider[0].url;
    selfServiceBanner.id = _banner.banner.slider[0].id;
  }
  return selfServiceBanner;
};
const convertDBProfileToSelfServiceScheme = (inData) => {
  const data = (path, defaultVal = '') =>
    isNil(get(inData, path)) ? defaultVal : get(inData, path);
  const checkEmpty = (path, defaultVal) =>
    isEmpty(get(inData, path)) || isNil(get(inData, path))
      ? defaultVal
      : get(inData, path);

  const converted = selfServiceSchema();
  converted.profileId = data('id');
  converted.publishedAt = data('publishedAt');
  converted.banners = data('banners', []);
  converted.mobileBanner = fetchBannerType(data('banners', []), 'mobileBanner');
  converted.desktopBanner = fetchBannerType(
    data('banners', []),
    'desktopBanner'
  );
  converted.isMaster = data('masterOf', undefined) ? true : false;
  converted.hasMaster = data('masterProfile', false) ? true : false;
  converted.masterProfile = converted.isMaster
    ? {}
    : data('masterProfile', false)
    ? convertDBProfileToSelfServiceScheme(data('masterProfile', {}))
    : {};
  converted.aboutMeVideoHelperLink = data(
    'company.aboutMeVideoHelperLink',
    '#'
  );
  converted.titleSize = data('title_size');
  converted.subtitleSize = data('subtitle_size');

  converted.availableThemes = data('company.themes', []);
  converted.navigateToOffice =
    data('contacts', [{ key: 'navigate-to-office', value: '' }]).filter(
      (e) => e.key === 'navigate-to-office'
    )?.[0]?.value || '';
  converted.subscriptionType = data('subscriptionType', '');

  converted.infoSections = chain(data('additional_information', []))
    .filter(filterInfoSections)
    .map((o) => omit(o, ['id']))
    .value();

  converted.infoPopups = chain(data('additional_information', []))
    .filter(filterInfoSectionsModal)
    .map((o) => omit(o, ['id']))
    .value();

  converted.banner = chain(data('banner.slider', []))
    .filter((e) => e.url)
    .defaultTo([])
    .map((e) => {
      return {
        url: e.url,
        id: e.id,
        width: e.width,
        height: e.height,
        key: e.key || uuidv4.v4()
      };
    })
    .first()
    .value();
  converted.pendingApproval =
    filter(data('approvals', []), (appr) => {
      return appr.approved == null;
    }).length > 0
      ? filter(data('approvals', []), (appr) => {
          return appr.approved == null;
        })[0].id
      : false;
  converted.qrcode = data('qrcode', null);
  converted.qrCodeData = data('qrCodeData', undefined);
  converted.profileDisclaimer = data('page_footer');
  converted.company = data('company', {});
  converted.companyIcons = checkEmpty('company.defaultIcons', undefined);
  converted.companySocials = checkEmpty('company.defaultSocials', undefined);
  converted.billing = {
    status: data('billing.status', undefined),
    token: data('billing.token', undefined),
    billingDate: data('billing.billingDate', undefined),
    amountGross: data('billing.amountGross', undefined),
    provider: data('billing.provider', undefined),
    expirationDate: data('billing.expirationDate', undefined)
  };
  converted.limits = {
    imageGallery: data('limits.imageGallery', 3),
    banner: data('limits.banner', 5)
  };
  converted.mainActionButton = {
    ...converted.mainActionButton,
    title: data('call_to_action.content', ''),
    url: data('call_to_action.link', ''),
    icon: data('call_to_action.icon', '')
  };
  converted.companyId = data('company.id', 0);
  converted.companyName = data('company.title', '');
  converted.emailAddress = data('contacts', []).filter(
    (e) => e.key === 'email'
  )[0]?.value;
  converted.firstName = data('person.first_name', '');
  converted.lastName = data('person.last_name', '');
  converted.profileName =
    data('person.first_name', '') + ' ' + data('person.last_name', '');

  converted.userId = data('user.id', 0);
  converted.userAccountEmail = data('userAccountEmail', '');
  converted.iconStyle = data('iconStyle', '');
  converted.jobTitle = data('biography.job_title', '');
  converted.links = data('contacts', [])
    .filter(
      (e) =>
        e.contact_type !== 'email' &&
        e.contact_type !== 'phone' &&
        e.contact_type !== 'socialmedia' &&
        e.key !== converted.mainActionButton.key &&
        e.key !== 'navigate-to-office'
    )
    ?.map((e) => {
      const isFullWidthButton =
        e.class && e.class.includes('w-full') ? true : false;
      return {
        url: e.value,
        title: e.name,
        key: e.key,
        icon: e.icon,
        id: e.id,
        enabled: e.enabled,
        contactType: e.contact_type,
        contact_type: e.contact_type,
        class: e.class,
        isFullWidthButton
      };
    });

  converted.permissions = converted.isMaster
    ? data('master_permission.permissions', [])
    : // eslint-disable-next-line no-undef
      ProfileMeLibrary.getPermissions(inData);
  /**
   * Master permisions can only be eddited by profileme admin
   *
   * manage permissions will always use the child permissions from the master or the child itself, depening
   * on the profile you are editing
   */
  converted.permissionId = data('child_permission.id', undefined);
  converted.childPermissions = data('child_permission.permissions', []);

  converted.masterPermissionId = data('master_permission.id', undefined);
  converted.masterPermissions = data('master_permission.permissions', []);

  converted.logo = {
    url: data('logo.url', null),
    id: data('logo.id', null)
  };
  converted.companyLogo = {
    url: data('company.logo.url', null),
    id: data('company.logo.id', null)
  };
  converted.mainContactNumber = data('contacts', []).filter(
    (e) => e.key === 'mobile'
  )[0]?.value;
  converted.whatsappNumber = data('contacts', []).filter(
    (e) => e.key === 'whatsapp'
  )[0]?.value;
  converted.officeNumber = data('contacts', []).filter(
    (e) => e.key === 'office'
  )[0]?.value;
  converted.subdomain = data('company.name', '');
  converted.referral_message = data('referral_message', '');
  converted.profileAlias = data('profileAlias', '');
  converted.homePage = data('company.homePage', '');
  converted.domain = {
    siteId: converted.homePage.netlifySiteID,
    domainName: converted.homePage.domainName
  };
  converted.profilePhoto = {
    url: data('person.profile_gallery[0].url', ''),
    id: data('person.profile_gallery[0].id', '')
  };
  converted.socialLinks = data('contacts', [])
    .filter((e) => e.contact_type === 'socialmedia')
    ?.map((e) => {
      return {
        url: e.value,
        key: e.key,
        title: e.name,
        id: e.id,
        icon: e.icon,
        enabled: e.enabled
      };
    });
  converted.videoGallery = data('video_gallery', [])?.map((i) => {
    return {
      url: convertEmbedToURL(i.embed),
      title: i.title,
      enabled: i.enabled != false,
      type: i.type,
      id: i.key,
      key: i.key || uuidv4.v4().toString()
    };
  });

  converted.imageGallery = chain(data('image_gallery', []))
    .filter('image')
    .defaultTo([])
    .map((i) => {
      return {
        url: i.url,
        title: i.title,
        link: i.link,
        id: i.image.id,
        type: i.type,
        expireAt: i.expireAt ?? null,
        expired: i.expireAt
          ? new Date(i.expireAt).getTime() < new Date().getTime()
          : false,
        key: i.key || uuidv4.v4().toString(),
        source: converted.isMaster ? 'master' : 'self',
        image: i.image,
        enabled: i.enabled != false
      };
    })
    .value();
  converted.testimonials = data('testimonials', []);
  converted.aboutMeVideo = convertEmbedToURL(data('about_me_video', ''));
  converted.aboutMeVideoEnabled = data('about_me_video_enabled', false);
  converted.enableConsent = data('company.consent.enable', false);
  converted.consentText = data('company.consent.text', '');

  const defaultEmailForLeadForm = () => {
    if (data('leadFormEmail')) {
      return data('leadFormEmail');
    } else if (data('masterProfile.leadFormEmail')) {
      return data('masterProfile.leadFormEmail');
    } else {
      if (userRole(data('user')) == 'user') {
        return data('user.email');
      } else {
        return '';
      }
    }
  };
  converted.sendToMail = defaultEmailForLeadForm();
  converted.sendToMailCC = data('leadFormEmailCC');

  converted.stylingTemplateCssPlug = data('profile_styling.cssPlug', '');
  converted.cssPlug = data('cssPlug', '');
  converted.stylingId =
    data('profile_styling.id', null) || data('profile_styling', null);
  converted.templateLayout = data('profile_styling.layout', undefined);
  converted.fontFamily = data('profile_styling.fontFamily', '');

  converted.cssVariables = data('cssVariables', {}) || {};
  converted.variableChangeRecords = data('variableChangeRecords', {}) || {};
  converted.fullProfile = inData;
  return converted;
};
const convertSelfServiceSchemeToDBProfile = (
  data = selfServiceSchema(),
  referenceId = true
) => {
  const formatContacts = () => {
    // contact_type:
    //  - "url",
    //  - "phone",
    //  - "email",
    //  - "socialmedia",
    //  - "social-media",
    //  - "modal"
    //  - "placeholder"

    const getLink = (item) => ({
      key:
        item.key ||
        item.title?.split(' ').join('-').toLocaleLowerCase() ||
        new Date().getTime().toString(),
      icon: item.icon || 'fa-{type} fas fa-star',
      name: item.title,
      value: item.url,
      contact_type: item.contactType || 'url',
      enabled: item.enabled,
      class: item.class
    });
    const getSocialMediaLink = (item) => ({
      key:
        item.key ||
        item.title?.split(' ').join('-').toLocaleLowerCase() ||
        new Date().getTime().toString(),
      name: item.title,
      value: item.url,
      icon: item.icon,
      contact_type: 'socialmedia',
      enabled: item.enabled
    });
    const result = chain([])
      .concat(
        chain(get(data, 'fullProfile.contacts', []))
          .reduce((acc, item) => {
            if (data.emailAddress && item.key == 'email')
              acc.push({
                ...item,
                value: data.emailAddress || item.value
              });
            else if (data.mainContactNumber && item.key == 'mobile')
              acc.push({
                ...item,
                value: data.mainContactNumber || item.value
              });
            else if (data.whatsappNumber && item.key == 'whatsapp')
              acc.push({
                ...item,
                value: data.whatsappNumber || item.value
              });
            else if (data.officeNumber && item.key == 'office')
              acc.push({
                ...item,
                value: data.officeNumber || item.value
              });
            else if (data.navigateToOffice && item.key == 'navigate-to-office')
              acc.push({
                ...item,
                value: data.navigateToOffice || item.value
              });

            return acc;
          }, [])
          .value()
      )
      .concat(map(data.links || [], getLink))
      .concat(map(data.socialLinks || [], getSocialMediaLink))
      .uniqBy('key')
      .value();

    const contactDoesntExists = (key) => !find(result, { key });

    if (data.emailAddress && contactDoesntExists('email'))
      result.unshift({
        key: 'email',
        icon: 'fa-{type} fal fa-envelope',
        name: 'Email Me',
        value: data.emailAddress,
        contact_type: 'email'
      });

    if (data.mainContactNumber && contactDoesntExists('mobile'))
      result.unshift({
        key: 'mobile',
        icon: 'fa-{type} fal fa-mobile-android',
        name: 'Call Me',
        value: data.mainContactNumber,
        contact_type: 'phone'
      });

    if (data.whatsappNumber && contactDoesntExists('whatsapp'))
      result.unshift({
        key: 'whatsapp',
        icon: 'fab fa-whatsapp',
        name: 'Whatsapp Me',
        value: data.whatsappNumber,
        contact_type: 'phone'
      });

    if (data.officeNumber && contactDoesntExists('office'))
      result.unshift({
        key: 'office',
        icon: 'fa-{type} fal fa-phone-rotary',
        name: 'Office',
        value: data.officeNumber,
        contact_type: 'phone'
      });

    if (data.navigateToOffice && contactDoesntExists('navigate-to-office'))
      result.unshift({
        key: 'navigate-to-office',
        icon: 'fa-{type} fa-location-dot',
        name: 'Navigate To Office',
        value: data.navigateToOffice,
        contact_type: 'url'
      });
    return result;
  };

  const formatAdditionalInformation = () => {
    // This allows additional_information to keep its misc entries
    return chain([...data.infoSections, ...data.infoPopups])
      .concat(
        filter(
          get(data, 'fullProfile.additional_information', []),
          (i) => !filterInfoSections(i) && !filterInfoSectionsModal(i)
        )
      )
      .value();
  };

  const DBSchema = {
    useProfilePictureAsVCFIcon: get(
      data,
      'fullProfile.useProfilePictureAsVCFIcon',
      true
    ),
    useProfilePictureAsPWAIcon: get(
      data,
      'fullProfile.useProfilePictureAsPWAIcon',
      true
    ),
    templateName: get(data, 'fullProfile.templateName', null),
    profileAlias: data?.profileAlias,
    referral_message: data?.referral_message,
    useProfilePictureAsIcon: get(
      data,
      'fullProfile.useProfilePictureAsIcon',
      null
    ),
    title_size: get(data, 'titleSize') || undefined,
    subtitle_size: get(data, 'subtitleSize') || undefined,
    profileIndexed: get(data, 'fullProfile.profileIndexed', true),
    iconStyle: data.iconStyle,
    page_footer: get(data, 'profileDisclaimer'),
    user: data.userId,
    banner: {
      url: null,
      slider: data.banner
        ? referenceId
          ? [data.banner?.id]
          : data.banner
          ? [data.banner]
          : []
        : [],
      embed_video: get(data, 'fullProfile.banner.embed_video', null)
    },
    /* we are returning banners */
    banners: referenceId
      ? map(get(data, 'banners', null), 'id')
      : get(data, 'banners', null),
    leadFormEmail: data.sendToMail,
    leadFormEmailCC: data.sendToMailCC,
    profile_styling:
      referenceId || !data.stylingId
        ? data.stylingId
        : {
            ...get(data, 'fullProfile.profile_styling', {}),
            cssPlug: data.stylingTemplateCssPlug,
            layout: data.templateLayout,
            fontFamily: data.fontFamily
          },
    contacts: formatContacts(),
    biography: {
      mission: null,
      services: [],
      job_title: data.jobTitle,
      title_prefix: null,
      title_suffix: null,
      qualifications: []
    },
    additional_information: formatAdditionalInformation(),
    profileName:
      data.fullProfile?.profileName?.trim() ||
      `${data.firstName} ${data.lastName}`,
    person: {
      gender: null,
      initials: null,
      languages: null,
      first_name: data.firstName,
      last_name: data.lastName,
      date_of_birth: null,
      profile_gallery: data.profilePhoto?.id
        ? [
            referenceId
              ? data.profilePhoto?.id
              : { ...data.profilePhoto, width: 160 }
          ]
        : []
    },
    location: [],
    call_to_action:
      data.mainActionButton?.title || data.mainActionButton?.url
        ? {
            key: data.mainActionButton.key,
            icon: data.mainActionButton.icon,
            content: data.mainActionButton?.title,
            link: data.mainActionButton?.url
          }
        : {},
    userAccountEmail: data.userAccountEmail,
    company: referenceId
      ? data.companyId
      : {
          ...data.fullProfile?.company
        } || undefined,
    logo: referenceId ? data?.logo?.id : collapseToNUll(data?.logo),
    cssVariables: omitBy(
      data.cssVariables,
      (e) => isNil(e) || isEmpty(e) || isNil(e?.css) || isEmpty(e?.css)
    ),
    variableChangeRecords: data.variableChangeRecords,
    // qrCodeData gets turned into an empty string for some reason
    // this helps with that.
    qrCodeData: data.qrCodeData || null,
    image_gallery: chain(data.imageGallery)
      .defaultTo([])
      .map((i) => ({
        ...i,
        image: referenceId ? i?.id : i?.image
      }))
      .map((i) => omit(i, 'id'))
      .value(),
    video_gallery: chain(data.videoGallery)
      .defaultTo([])
      .map((i) => ({
        ...i,
        embed: convertURLToEmbed(i.url)
      }))
      .map((i) => omit(i, 'id'))
      .value(),
    testimonials: chain(data.testimonials)
      .defaultTo([])
      .map((i) => omit(i, 'id'))
      .value(),
    about_me_video: convertURLToEmbed(data.aboutMeVideo),
    about_me_video_enabled: data.aboutMeVideoEnabled,
    qrcode: referenceId ? data?.qrcode?.id : data?.qrcode,
    footerLinks: data.fullProfile?.footerLinks,
    cssPlug: data.cssPlug
  };
  if (!DBSchema.user) {
    delete DBSchema.user;
  }

  function clean(object) {
    Object.entries(object).forEach(([k, v]) => {
      if (Array.isArray(object[k]) && object[k].length > 0) {
        if (v && typeof v === 'object') {
          clean(v);
        }
        if (
          (v && typeof v === 'object' && !Object.keys(v)?.length) ||
          v === null ||
          v === undefined
        ) {
          if (Array.isArray(object)) {
            object.splice(k, 1);
          } else {
            delete object[k];
          }
        }
      }
    });
    return object;
  }
  return clean(DBSchema);
};

export {
  convertDataToNewStructure,
  convertDBProfileToSelfServiceScheme,
  convertSelfServiceSchemeToDBProfile
};
