/* eslint-disable no-unused-vars */
import { arrayMergeWrapper } from '~/functions/wrappers/array-merge-wrapper';
import { sectionWrapper } from '~/functions/wrappers/section-wrapper';
import variableRecordWrapper from '~/functions/wrappers/variable-record-wrapper';
import {
  popupsParams,
  linksParams,
  socialMediaParams
} from '~/constants/arguments';
import {
  forEach,
  map,
  isArray,
  includes,
  flatten,
  filter,
  reduce
} from 'lodash';
import ProfileMeLibrary from '@profilemeapp/profile-me-library/dist/App/ProfileMeApp.mjs';

export default function (
  profile,
  permissionsHandler,
  reactive = () => {},
  shouldRemovePlaceholders = false
) {
  const addKeyIfThere = (key, append, separator) => {
    if (append) return key + (separator ? '.' + separator : '') + '.' + append;
    return key;
  };

  const canEdit = (key) => permissionsHandler.itemIsEditable(key);
  const filterChildBy = (key) =>
    !ProfileMeLibrary.recordWasRemoved(key, profile.variableChangeRecords);

  const useMasterValue = (permissionKey, recordKey) =>
    !canEdit(permissionKey) ||
    !ProfileMeLibrary.recordWasEdited(
      recordKey || permissionKey,
      profile.variableChangeRecords
    );

  const filterMasterBy = (key) =>
    // If you can't edit it, then the parent shouldn't be filtered
    // If a record was not removed, the the parent shouldn't be filtered
    !canEdit(key) ||
    !ProfileMeLibrary.recordWasRemoved(key, profile.variableChangeRecords);

  const removePlaceholders = (arr, removerMap, key, type) => {
    const changeKeys = flatten([key]);
    const changeValues = (item) =>
      changeKeys.forEach((k) => {
        item[k] = removerMap[item.key] ? item[k] : '';
      });
    arr.forEach((item) => {
      if (isArray(type)) {
        if (includes(type, item.type)) changeValues(item);
      } else if (type) {
        if (type == item.type) changeValues(item);
      } else changeValues(item);
    });
  };

  const variableRecordHandler = variableRecordWrapper(
    profile.variableChangeRecords,
    reactive
  );
  const sectionArrayHandler = arrayMergeWrapper(
    profile,
    profile.masterProfile,
    'infoSections',
    ({ key }) => key != 'ss-lead-form' && key != 'modal:lead-form',
    'key',
    reactive,
    (value, type, subKey) => {
      if (subKey)
        if (type == 'update')
          variableRecordHandler.addRecord(addKeyIfThere(subKey), type);
        else
          variableRecordHandler.addRecord(
            addKeyIfThere(subKey, 'content'),
            type
          );
      else variableRecordHandler.addRecord(addKeyIfThere('infoSections'), type);
    }
  );

  const removerMapDropdown = reduce(
    sectionArrayHandler.childArray,
    (acc, { key, description }) => {
      acc[key] = description && description != '<p><br></p>';
      return acc;
    },
    {}
  );
  sectionArrayHandler.addAllFromParentToChild(
    'title,icon,description',
    (key) => useMasterValue(key + '.content', key),
    (key) => filterMasterBy(key + '.content'),
    (key) => filterChildBy(key + '.content'),
    '',
    permissionsHandler.itemIsEditable('infoSections'),
    ProfileMeLibrary.appendMode(sectionArrayHandler.childArray),
    !permissionsHandler.itemIsEditable('infoSections')
  );
  if (shouldRemovePlaceholders)
    removePlaceholders(
      sectionArrayHandler.childArray,
      removerMapDropdown,
      'description',
      ['text', 'aboutme']
    );

  const itemsHandlerArray = map(
    sectionArrayHandler.childArray,
    ({ key, type }) =>
      arrayMergeWrapper(
        profile,
        profile.masterProfile,
        type,
        key
          ? {
              type: key
            }
          : null,
        'key',
        reactive,
        (value, type, subKey) => {
          variableRecordHandler.addRecord(subKey, type);
        }
      )
  );

  const sectionHandler = sectionWrapper(
    itemsHandlerArray,
    sectionArrayHandler,
    reactive
  );

  const popupsArrayHandler = arrayMergeWrapper(
    profile,
    profile.masterProfile,
    ...popupsParams,
    (value, type, key) => {
      variableRecordHandler.addRecord(addKeyIfThere('modals.list', key), type);
    }
  );
  const linksArrayHandler = arrayMergeWrapper(
    profile,
    profile.masterProfile,
    ...linksParams,
    (value, type, key) => {
      variableRecordHandler.addRecord(addKeyIfThere('links.list', key), type);
    }
  );
  const socialMediaArrayHandler = arrayMergeWrapper(
    profile,
    profile.masterProfile,
    ...socialMediaParams,
    (value, type, key) => {
      variableRecordHandler.addRecord(
        addKeyIfThere('socialMedia.list', key),
        type
      );
    }
  );

  forEach(
    sectionHandler.state.infoItems,
    ({ info, type, itemsArrayWrapper }) => {
      itemsArrayWrapper.addAllFromParentToChild(
        'enabled',
        (key) => useMasterValue(info.key + '.list.' + key),
        (key) => filterMasterBy(info.key + '.list.' + key),
        (key) => filterChildBy(info.key + '.list.' + key),
        type == 'imageGallery' || type == 'videoGallery' ? 'url' : null,
        permissionsHandler.itemIsEditable(info.permissionKey),
        ProfileMeLibrary.appendMode(itemsArrayWrapper.childArray)
      );
    }
  );

  linksArrayHandler.addAllFromParentToChild(
    'url,icon,title',
    (key) => useMasterValue('links.list.' + key),
    (key) => filterMasterBy('links.list.' + key),
    (key) => filterChildBy('links.list.' + key),
    '',
    permissionsHandler.itemIsEditable('links.content'),
    ProfileMeLibrary.appendMode(
      linksArrayHandler.childArray,
      linksArrayHandler.masterArray
    )
  );

  popupsArrayHandler.addAllFromParentToChild(
    'title,icon,description',
    (key) =>
      useMasterValue(
        'links.list.' + filter(linksArrayHandler.childArray, { url: key })?.url,
        'modals.list.' + key
      ),
    (key) =>
      filterMasterBy(
        'links.list.' + filter(linksArrayHandler.childArray, { url: key })?.url
      ),
    (key) =>
      filterChildBy(
        'links.list.' + filter(linksArrayHandler.childArray, { url: key })?.url
      ),
    '',
    permissionsHandler.itemIsEditable('links.content'),
    ProfileMeLibrary.appendMode(sectionArrayHandler.childArray),
    !permissionsHandler.itemIsEditable('links.content')
  );
  const removerMapModals = reduce(
    popupsArrayHandler.childArray,
    (acc, { key, description }) => {
      acc[key] = description && description != '<p><br></p>';
      return acc;
    },
    {}
  );
  if (shouldRemovePlaceholders)
    removePlaceholders(
      popupsArrayHandler.childArray,
      removerMapModals,
      'description',
      ['text', 'aboutme']
    );

  socialMediaArrayHandler.addAllFromParentToChild(
    'url,icon,title',
    (key) => useMasterValue('socialMedia.list.' + key),
    (key) => filterMasterBy('socialMedia.list.' + key),
    () => true,
    null,
    true,
    ProfileMeLibrary.appendMode(socialMediaArrayHandler.childArray)
  );

  return {
    popupsArrayHandler,
    sectionArrayHandler,
    itemsHandlerArray,
    sectionHandler,
    linksArrayHandler,
    socialMediaArrayHandler,
    variableRecordHandler
  };
}
