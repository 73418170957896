<template>
  <div class="bg-white relative" :class="{ 'pt-32': isModal }">
    <div
      :type="'primary'"
      v-if="isModal"
      class="bg-gray-300 flex justify-center items-center py-12"
      :class="{ '-mt-32': isModal }"
    >
      <slot></slot>
      <div
        @click="closeThis"
        class="flex justify-center items-center cursor-pointer"
      >
        <p class="font-bold text-xl md:text-2xl mr-2">
          Close profile preview
        </p>
        <i
          class="fa-solid fa-xmark bg-brand-color rounded-full h-10 ml-2 w-10 text-white text-center items-center justify-center flex p-3 text-lg"
        ></i>
      </div>
    </div>
    <iframe
      class="w-full h-full"
      ref="iframe"
      frameborder="0"
      :src="previewPage"
      @load="loaded"
    ></iframe>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { $vfm } from 'vue-final-modal';
import { ref, toRef } from '@vue/reactivity';

export default {
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    updatePreview: {
      type: Number,
      default: 0
    },
    profileChanges: {
      type: Array,
      default: () => []
    },
    isModal: {
      type: Boolean,
      default: true
    },
    previewPage: {
      type: String,
      default: '/profile-me-app-preview'
    }
  },
  emits: ['closePreview', 'loaded'],
  setup(props, { emit }) {
    const listOfProfileChanges = toRef(props, 'profileChanges');

    const iframe = ref(null);

    const closeThis = () => {
      emit('closePreview');
      $vfm.hide('modal-preview');
    };

    return {
      iframe,
      listOfProfileChanges,
      closeThis,
      loaded: () => {
        emit('loaded');
      }
    };
  }
};
</script>
