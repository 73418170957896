<script>
import VChangesTable from '~/components/functional/v-changes-table.vue';

export default {
  components: {
    VChangesTable
  },
  emits: ['closePreview'],
  props: {
    profileChanges: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    handleClose(e) {
      e.preventDefault();
      this.showModal = false;
    },
    handleOpen(e) {
      e.preventDefault();
      this.showModal = true;
    },
    prevent(e) {
      e.stopPropagation();
    }
  }
};
</script>
<template>
  <div class="flex items-center justify-end" v-show="profileChanges.length">
    <div class="flex justify-center items-center cursor-default">
      <p class="font-bold text-xl md:text-2xl mr-4"> Profile changes </p>
      <i
        class="bg-brand-color rounded-full h-10 w-10 text-white text-xl text-center items-center justify-center flex p-3 ml-2"
        >{{ profileChanges.length }}</i
      >
    </div>
    <div style="background: darkgray; width: 2px" class="h-8 mx-4 md:mx-8" />

    <div
      @click="handleOpen"
      class="flex justify-center items-center cursor-pointer"
    >
      <p class="font-bold text-brand-color text-xl md:text-2xl mr-2 underline">
        View updates
      </p>
      <i
        class="fa-solid fa-eye bg-brand-color rounded-full h-10 w-10 ml-2 text-white text-xl text-center items-center justify-center flex p-3"
      ></i>
    </div>
    <div style="background: darkgray; width: 2px" class="h-8 mx-4 md:mx-8" />
    <div
      class="w-screen h-screen top-0 left-0 absolute flex justify-center items-center"
      :style="{
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      }"
      v-show="showModal"
      @click="handleClose"
    >
      <span
        @click="handleClose"
        class="cursor-pointer text-2xl absolute right-5 top-5"
      >
        <i
          class="fa-solid fa-xmark bg-brand-color rounded-full h-10 ml-2 w-10 text-white text-center items-center justify-center flex p-3 text-lg"
        ></i>
      </span>
      <v-changes-table
        @click="prevent"
        class="bg-white"
        :changes="profileChanges"
      />
    </div>
  </div>
</template>
