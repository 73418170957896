import './index.css';
import 'vue-loaders/dist/vue-loaders.css';

import App from './App.vue';
import VueLoaders from 'vue-loaders';
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import { createApp } from 'vue';
import router from './router/index.js';
import store from './store';
import toast from './components/plugins/toast/toast';

// css editor
import ace from 'ace-builds';

import modeHtmlUrl from 'ace-builds/src-noconflict/mode-css?url';
ace.config.setModuleUrl('ace/mode/css', modeHtmlUrl);

import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);

import workerBaseUrl from 'ace-builds/src-noconflict/worker-base?url';
ace.config.setModuleUrl('ace/mode/base', workerBaseUrl);

import workerHtmlUrl from 'ace-builds/src-noconflict/worker-css?url';
ace.config.setModuleUrl('ace/mode/css_worker', workerHtmlUrl);

import snippetsHtmlUrl from 'ace-builds/src-noconflict/snippets/css?url';
ace.config.setModuleUrl('ace/snippets/css', snippetsHtmlUrl);

import 'ace-builds/src-noconflict/ext-language_tools';
ace.require('ace/ext/language_tools');

// maps api
const _head = document.querySelector('head');
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API_KEY}&libraries=places`;
_head.appendChild(script);

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = '/index.css';
_head.prepend(link);
// Create App
const app = createApp(App);

app.use();
app.component('DatePicker', DatePicker);
// Add pluginsz
app.use(router);
app.use(store);
app.use(toast);
app.provide('toast', app.config.globalProperties.$toast);

router.app = app;
app.use(VueLoaders);

// Mount App
app.mount('#app');
